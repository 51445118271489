import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {Chip, Tooltip} from '@mui/material'
import React, {FC} from 'react'

import {useMobile} from 'core/hooks'
import {formatDateTime} from 'core/locale'
import {useGetTaxApiStatusQuery} from 'generated/graphql'

export const ApiStatusAlert: FC = () => {
    const isMobile = useMobile()
    const pollInterval = 1000 * 60
    const {data} = useGetTaxApiStatusQuery({pollInterval: pollInterval})

    const title = `Serviço de impostos: ${data?.taxApiStatus.status ? 'Ativo' : 'Não ativo'}`
    const description = `${formatDateTime(data?.taxApiStatus.created)}: ${data?.taxApiStatus.message || ''}`

    return (
        !isMobile
            ? <Tooltip title={description}>
                <Chip
                    label={title}
                    style={{backgroundColor: data?.taxApiStatus.status ? '#00B050' : '#E33D3D', color: '#FFF'}}
                />
            </Tooltip>
            : <Tooltip title={<>{title} - {description}</>}>
                {data?.taxApiStatus.status
                    ? <CheckCircleOutlineIcon style={{color: '#00B050'}} />
                    : <ErrorOutlineIcon style={{color: '#E33D3D'}} />
                }
            </Tooltip>
    )
}
