import {
    CircularProgress,
    createFilterOptions,
    Grid,
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
    TextField,
} from '@mui/material'
import {useField, useFormikContext} from 'formik'
import {useEffect, useMemo} from 'react'

import {FormStatus, setFilterFieldValue} from 'core/components/forms'
import {useMobile} from 'core/hooks'
import {isString} from 'guards'
import {Only} from 'types'

import {defaultGridProps, FormChild} from './form'

type BaseAutocompleteProps<T extends object> = {
    valueKey: Only<T, string | undefined>
    labelKey: Only<T, string | undefined>
}

export type AutocompletePublicProps<T extends object = any> = FormChild & {
    name: string
    label?: string
    disabled?: boolean
    loading?: boolean
    submitOnSelect?: boolean
    disableClearable?: boolean
    required?: boolean
    filterOptions?: (option: T) => boolean
} & Pick<MUIAutocompleteProps<T, false, false, false>, 'renderOption' | 'size' | 'fullWidth'>

type AutocompleteProps<T extends object> = FormChild &
    BaseAutocompleteProps<T> &
    AutocompletePublicProps &
    Pick<MUIAutocompleteProps<T, false, false, false>, 'options' | 'groupBy' | 'renderOption'> & {defaultValue?: T}

export function Autocomplete<T extends object>(props: AutocompleteProps<T>) {
    const {
        name,
        label,
        disabled,
        loading,
        defaultValue,
        submitOnSelect,
        options,
        disableClearable,
        valueKey,
        labelKey,
        gridProps,
        size,
        required,
        renderOption,
        filterOptions,
        fullWidth,
        groupBy,
    } = props
    const isMobile = useMobile()
    const filteredOptions = useMemo(
        () => (filterOptions && options.filter(filterOptions)) || options,
        [filterOptions, options],
    )
    const {isSubmitting, setFieldValue, status, submitForm} = useFormikContext()
    const [field, meta] = useField<string>(name)
    const value =
        options.find(option => {
            const value = option[valueKey]
            return isString(value) && value === field.value
        }) || null

    // All props will trigger a re-render.
    const memoDeps = Object.keys(props)
        .sort()
        .map(key => (props as any)[key])
        .concat([value, isSubmitting, isMobile])

    useEffect(() => {
        if (status === FormStatus.filters) {
            setFilterFieldValue(
                name,
                {
                    type: 'single',
                    value: {
                        value: value?.[valueKey],
                        label: `${label}: ${value?.[labelKey]}`,
                        name,
                        handleDelete: () => {
                            setFieldValue(name, '', true)
                            submitForm()
                        },
                    },
                },
                setFieldValue,
            )
        }
    }, [field.value, label, status])

    const component = useMemo(
        () => (
            <MUIAutocomplete<T, false, typeof disableClearable, false>
                value={value}
                options={filteredOptions}
                noOptionsText='Sem opções'
                getOptionLabel={option => option[labelKey] as any}
                isOptionEqualToValue={(option, selected) => option[valueKey] === selected[valueKey]}
                filterOptions={createFilterOptions({stringify: option => option[labelKey] as string})}
                fullWidth={fullWidth}
                multiple={false}
                blurOnSelect={true}
                size={size}
                openOnFocus={true}
                disableClearable={disableClearable}
                disabled={isSubmitting || disabled}
                loading={loading}
                defaultValue={defaultValue}
                renderOption={
                    renderOption
                        ? renderOption
                        : (props, option) => {
                              const label = option[labelKey] as string,
                                  value = option[valueKey]
                              return (
                                  <li
                                      {...props}
                                      key={(typeof value === 'string' && value) || (value as any).toString()}>
                                      {label}
                                  </li>
                              )
                          }
                }
                groupBy={groupBy}
                renderGroup={params => {
                    return (
                        <li key={params.key}>
                            <div>{params.group}</div>
                            <ul>{params.children}</ul>
                        </li>
                    )
                }}
                onChange={(_event: any, option: T | null) => {
                    // When this issue is merged we can pass shouldValidate
                    // directly to helpers.setValue and helpers.setTouched.
                    // https://github.com/jaredpalmer/formik/pull/2371
                    setFieldValue(name, option ? option[valueKey] : '', true)
                    if (submitOnSelect) submitForm()
                }}
                onBlur={() => {
                    // FIXME: The error is persisted and you have to trigger the blur or change event again
                    // to actually clear the previous error.
                    // helpers.setTouched(true, true);
                    // setFieldTouched(name, true, true);
                }}
                renderInput={params => (
                    <TextField
                        variant='outlined'
                        label={label}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                        {...params}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            required,
                            shrink: isMobile ? true : undefined,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading && (
                                        <CircularProgress
                                            size={20}
                                            color='primary'
                                        />
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        ),
        memoDeps,
    )

    if (!gridProps) return component

    return (
        <Grid
            item={true}
            {...gridProps}>
            {component}
        </Grid>
    )
}

Autocomplete.defaultProps = {
    gridProps: defaultGridProps,
}
