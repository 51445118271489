import {
    AttachMoney as AttachMoneyIcon,
    CloudDownload as CloudDownloadIcon,
    Description as DescriptionIcon,
    FeaturedPlayList as FeaturedPlayListIcon,
    Home as HomeIcon,
    List as ListIcon,
    LocalShipping,
    LocationCity as LocationCityIcon,
    PaymentOutlined as PaymentOutlinedIcon,
    Person as PersonIcon,
    Settings as SettingsIcon,
    Star as StarIcon,
    TableChart,
    TrendingUp as TrendingUpIcon,
} from '@mui/icons-material'
import {List, Theme} from '@mui/material'
import React, {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import {FastTruckIcon} from 'components/icons/fast-truck-icon'
import {CatalogIcon} from 'components/rops/catalog-icon'
import {RopIcon} from 'components/rops/rop-icon'
import {TryAndBuyIcon} from 'components/try-and-buy/try-and-buy-icon'
import {useUser} from 'context/user'

import AppMenuLink from './app-menu-link'

const useStyles = makeStyles()((theme: Theme) => ({
    list: {
        paddingRight: theme.spacing(1),
    }
}))

const AppMenu: FC = () => {
    const {user} = useUser()
    const {classes} = useStyles()

    return (
        <List component='nav' className={classes.list}>
            <AppMenuLink href='/' label='Home' icon={<HomeIcon />} />
            <AppMenuLink href='/quotes' label='Cotações' icon={<AttachMoneyIcon />} permission='viewQuotes' />
            <AppMenuLink href='/supply-orders' label='Ordens de compra' icon={<LocalShipping />} permission='viewSupplyOrders'>
                {user?.permissions.manageShipments && (
                    <>
                        <AppMenuLink href='/supply-orders' label='Lista de ordens de compra' icon={<ListIcon />} />
                        <AppMenuLink href='/supply-orders/order-follow-up' label='Acompanhamento de Pedido' icon={< TableChart />} />
                    </>
                )}
            </AppMenuLink>
            <AppMenuLink href='/sales' label='Vendas' icon={<PaymentOutlinedIcon />} permission='viewSales' />
            <AppMenuLink href='/accounts' label='Contas' icon={<LocationCityIcon />} permission='viewAccounts' />
            <AppMenuLink href='/contacts' label='Contatos' icon={<PersonIcon />} permission='viewContacts' />
            <AppMenuLink href='/opportunities' label='Oportunidades' icon={<StarIcon />} permission='viewOpportunities' />
            <AppMenuLink href='/try-and-buy' label='Try & Buy' icon={<TryAndBuyIcon />} permission='viewTryAndBuy'>
                <AppMenuLink href='/try-and-buy' label='Try & Buy' icon={<ListIcon />} />
                <AppMenuLink href='/try-and-buy/supply-orders' label='Lista de remessas' icon={<FastTruckIcon />} />
            </AppMenuLink>
            <AppMenuLink href='/analytics' label='Estatísticas' icon={<TrendingUpIcon />} permission='viewAnalytics' />
            <AppMenuLink href='/warranty-claims' label='Reivindicação de garantia' icon={<DescriptionIcon />} permission='viewWarrantyClaim' />
            <AppMenuLink href='/rops' label='ROP' icon={<RopIcon />} permission='viewRops' />
            <AppMenuLink href='/organization-documents' label='Documentos' icon={<CloudDownloadIcon />} />
            <AppMenuLink href='/stock' label='Estoque' icon={<FeaturedPlayListIcon />} permission='viewStock' />
            <AppMenuLink href='/catalog' label='Catálogo' icon={<CatalogIcon />} permission='viewCatalog' />
            {user?.isStaff && <AppMenuLink href='/admin' label='Administração' newTab={true} icon={<SettingsIcon />} />}
        </List>
    )
}

export default AppMenu
