import {SvgIcon, SvgIconProps} from '@mui/material'
import {FC} from 'react'

export const CatalogIcon: FC<SvgIconProps> = ({...props}) => {
    return (
        <SvgIcon {...props} viewBox='0 0 36 36' width={36} height={36}>
            <path
                d='M16.1625 30.9754H6.75C6.15 30.9754 5.625 30.7504 5.175 30.3004C4.725 29.8504 4.5 29.3254 4.5 28.7254V6.90039C4.5 6.30039 4.69375 5.77539 5.08125 5.32539C5.46875 4.87539 5.8 4.65039 6.075 4.65039H13.65C13.825 3.77539 14.2562 3.05664 14.9437 2.49414C15.6312 1.93164 16.425 1.65039 17.325 1.65039C18.225 1.65039 19.0187 1.93164 19.7062 2.49414C20.3938 3.05664 20.825 3.77539 21 4.65039H28.575C29.175 4.65039 29.7 4.87539 30.15 5.32539C30.6 5.77539 30.825 6.30039 30.825 6.90039V14.5129H28.575V6.90039H24.6V11.7754H10.725V6.90039H6.75V28.7254H16.1625V30.9754ZM23.25 30.0379L17.25 24.0379L18.8625 22.4254L23.25 26.8129L32.2125 17.8504L33.825 19.4629L23.25 30.0379ZM18 6.75039C18.425 6.75039 18.7812 6.60664 19.0687 6.31914C19.3562 6.03164 19.5 5.67539 19.5 5.25039C19.5 4.82539 19.3562 4.46914 19.0687 4.18164C18.7812 3.89414 18.425 3.75039 18 3.75039C17.575 3.75039 17.2188 3.89414 16.9313 4.18164C16.6438 4.46914 16.5 4.82539 16.5 5.25039C16.5 5.67539 16.6438 6.03164 16.9313 6.31914C17.2188 6.60664 17.575 6.75039 18 6.75039Z'
            />
        </SvgIcon>
    )
}
