import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {useRouter} from 'next/router'
import React, {FC, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {useMenu} from 'components/layout'
import {Link} from 'components/link'
import {useUser} from 'context/user'
import {UserFragment} from 'generated/graphql'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        marginLeft: theme.spacing(1),
    },

    selected: {
        color: theme.palette.secondary.light,
        backgroundColor: 'transparent !important',
    },

    nested: {
        paddingLeft: theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
    }
}))

interface AppMenuLinkProps {
    href: string
    label: string
    icon: React.ReactElement
    children?: React.ReactNode,
    newTab?: boolean,
    permission?: keyof UserFragment['permissions'] | (keyof UserFragment['permissions'])[]
}

const AppMenuLink: FC<AppMenuLinkProps> = ({href, label, icon, children, newTab, permission}) => {
    const {user} = useUser()
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const {drawerOpenedWithMenuIcon, drawerOpenedWithlHover, setDrawerOpenedWithMenuIcon} = useMenu()
    const {classes} = useStyles()
    const router = useRouter()

    const isExternalLink = href.startsWith('https://') || href.startsWith('http://')
    const isExpandable = Boolean(children)
    const [expanded, setExpanded] = useState<boolean>(router.pathname.startsWith(href))

    const linkIsActive = !isExpandable ? router.pathname === href : router.pathname.startsWith(href)

    const button = (
        <ListItemButton
            selected={linkIsActive}
            classes={{selected: classes.selected, root: classes.root}}
            onClick={() => isExpandable
                ? setExpanded(!expanded)
                : isSm && setDrawerOpenedWithMenuIcon(false)
            }>
            <ListItemIcon>
                {React.cloneElement(icon, {color: linkIsActive ? 'secondary' : 'inherit'})}
            </ListItemIcon>
            <ListItemText primary={label} />
            {isExpandable && (
                expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
            )}
        </ListItemButton>
    )

    if (permission && user) {
        if (Array.isArray(permission)) {
            if (!permission.every(p => Boolean(user.permissions[p]))) return null
        } else if (!Boolean(user.permissions[permission])) return null
    };

    if (isExternalLink || newTab) {
        return React.cloneElement(button, {href, target: '_blank'})
    }

    return (
        <>
            {isExpandable ? button : <Link href={href}>{button}</Link>}
            {isExpandable && (
                <Collapse in={expanded}>
                    <List disablePadding={true} className={drawerOpenedWithMenuIcon || drawerOpenedWithlHover ? classes.nested : ''}>
                        {children}
                    </List>
                </Collapse>
            )}
        </>
    )
}

export default AppMenuLink
