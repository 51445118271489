import {Grid, InputBaseProps, TextField, TextFieldProps} from '@mui/material'
import {FastField, FieldProps} from 'formik'
import React, {FC, useMemo} from 'react'
import MaskedInput, {MaskedInputProps} from 'react-text-mask'

import {defaultGridProps, FormChild} from './form'

type BaseTextInputProps = {
    name: string
}

export type TextInputProps = FormChild
    & BaseTextInputProps
    & Partial<Pick<MaskedInputProps, 'mask'>>
    & Pick<TextFieldProps, 'label' | 'disabled' | 'placeholder' | 'multiline' | 'rows' | 'type' | 'inputMode' | 'size' | 'className'>
    & Pick<InputBaseProps, 'startAdornment' | 'endAdornment'>

interface MaskedInputWrapperProps {
    inputRef: (ref: HTMLInputElement | null) => void
}

export const TextInput: FC<TextInputProps> = ({
    name,
    disabled,
    inputMode,
    startAdornment,
    endAdornment,
    mask,
    gridProps,
    ...otherProps
}) => {
    const inputComponent: any = useMemo(() => {
        if (!mask) return 'input'
        const MaskedInputWrapper: FC<MaskedInputWrapperProps> = ({inputRef, ...wrapperOtherProps}) => {
            return (
                <MaskedInput
                    {...wrapperOtherProps}
                    mask={mask}
                    guide={false}
                />
            )
        }
        return MaskedInputWrapper
    }, [mask])

    const InputProps: Partial<InputBaseProps> = {inputComponent}
    if (startAdornment) InputProps.startAdornment = startAdornment
    if (endAdornment) InputProps.endAdornment = endAdornment

    const component = (
        <FastField name={name}>
            {({field, form, meta}: FieldProps) => (
                <TextField
                    variant='outlined'
                    fullWidth={true}
                    disabled={form.isSubmitting || disabled}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    inputProps={{inputMode}}
                    InputProps={InputProps}
                    {...field}
                    {...otherProps}
                />
            )}
        </FastField>
    )

    if (!gridProps) return component

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    )
}

TextInput.defaultProps = {
    gridProps: defaultGridProps,
}
