import {Button} from '@mui/material'
import {Formik, FormikErrors, FormikHelpers} from 'formik'
import React, {FC} from 'react'

import {ChildOrganizationAutocomplete, LocationAutocomplete, RegionAutocomplete} from 'components/autocompletes'
import {useUser} from 'context/user'
import {Autocomplete, Form, FormActions, FormErrors, SubmitButton, TextInput} from 'core/components/forms'
import {CreateAccountMutationInput, UpdateAccountMutationInput} from 'generated/graphql'
import {useGetTaxIdLabel} from 'utils'

export type AccountFormValues = CreateAccountMutationInput | UpdateAccountMutationInput

export interface AccountFormProps {
    initialValues?: Partial<AccountFormValues>
    onSubmit(values: AccountFormValues, actions: FormikHelpers<AccountFormValues>): void
    onCancel?(): void
    extraValidation?: (values: AccountFormValues, errors: FormikErrors<AccountFormValues>) => FormikErrors<AccountFormValues>
}

export const AccountForm: FC<AccountFormProps> = ({initialValues, onSubmit, onCancel, extraValidation}) => {
    const {user} = useUser()
    const {assignAccountOwner} = user?.permissions || {}
    const defaultInitialValues: AccountFormValues = {
        name: '',
        taxId: '',
        region: '',
        externalId: '',
        owner: !assignAccountOwner && user?.organizations?.[0]?.id || '',
        location: '',
        address: '',
        businessNumber: '',
    }
    const taxId = useGetTaxIdLabel()

    const validate = async (values: AccountFormValues) => {
        let errors: FormikErrors<AccountFormValues> = {}
        if (!/^[A-Za-zÀ-ú0-9\s'"]+$/.test(values.name)) {
            errors.name = 'o nome só pode conter letras ou números'
        }
        if (/^\d+$/.test(values.name)) {
            errors.name = 'O nome não pode conter apenas números';
        }
        if (values.taxId && !/^[0-9.-]*$/.test(values.taxId)) {
            errors.taxId = 'O campo só pode conter números, pontos e traços'
        }
        if (extraValidation) return extraValidation?.(values, errors)
        return errors
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{...defaultInitialValues, ...(initialValues || {})}} validate={validate}>
            {formikProps => (
                <Form withBackdrop={true}>
                    <TextInput name='name' label='Razão social' />
                    <TextInput name='taxId' label={taxId} />
                    <RegionAutocomplete name='region' />
                    <LocationAutocomplete name='location' />
                    <TextInput name='address' label='Endereço' />
                    <TextInput name='businessNumber' label='IE' />
                    <TextInput name='externalId' label='ID JDE' />
                    {assignAccountOwner && (
                        <ChildOrganizationAutocomplete name='owner' queryVariables={{onlyPublished: true}} />
                    ) ||
                        user?.organizations.some(org => org.id === formikProps.values.owner)
                        && (
                            <Autocomplete
                                name='owner'
                                options={user?.organizations.map(({id, name}) => ({id, name})) || []}
                                valueKey='id'
                                labelKey='name'
                                label='Organização atribuída'
                                disabled={user?.organizations.length === 1}
                            />
                        )}
                    <FormActions>
                        <SubmitButton label='Salvar' submittingLabel='Salvando...' />
                        {onCancel && <Button onClick={onCancel}>Cancelar</Button>}
                        <FormErrors />
                    </FormActions>
                </Form>
            )}
        </Formik>
    )
}
