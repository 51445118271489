import {FC} from 'react';

import {
    Autocomplete,
    AutocompleteMultiple,
    AutocompleteMultiplePublicProps,
    AutocompletePublicProps,
} from 'core/components/forms';
import {QuotesQuoteStatusChoices} from 'generated/graphql';

const options = [
    {value: QuotesQuoteStatusChoices.InProgress.toLowerCase(), label: 'Em operação'},
    {value: QuotesQuoteStatusChoices.InSale.toLowerCase(), label: 'Em venda'},
    {value: QuotesQuoteStatusChoices.InSupplyOrder.toLowerCase(), label: 'Em ordem de compra'},
    {value: QuotesQuoteStatusChoices.Closed.toLowerCase(), label: 'Fechada'},
    {value: QuotesQuoteStatusChoices.InTryAndBuy.toLowerCase(), label: 'Try & buy'},
];
export const QuoteStatusAutocomplete: FC<AutocompletePublicProps> = props => (
    <Autocomplete
        options={options}
        valueKey='value'
        labelKey='label'
        label='Status'
        {...props}
    />
);

export const QuoteStatusAutocompleteMultiple: FC<AutocompleteMultiplePublicProps> = props => (
    <AutocompleteMultiple
        options={options}
        valueKey='value'
        labelKey='label'
        label='Status'
        {...props}
    />
);
