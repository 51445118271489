import {Grid} from '@mui/material'
import Alert from '@mui/material/Alert'
import {isString, useField, useFormikContext} from 'formik'
import {FC, PropsWithChildren} from 'react'

export const FormErrors: FC<Partial<FormFieldErrorProps>> = ({name}) => {
    if (name) return <FormFieldError name={name} />
    return <FormError />
}

const FormError: FC<PropsWithChildren> = () => {
    const {status} = useFormikContext()
    if (!status?.formErrors) return null
    return <ErrorWrapper>{status.formErrors}</ErrorWrapper>
}

type FormFieldErrorProps = {name: string, checkTouched?: boolean}

const FormFieldError: FC<FormFieldErrorProps> = ({name, checkTouched}) => {
    const [, meta] = useField(name)
    if (checkTouched && !meta.touched) return null
    if (!Boolean(meta.error) || !isString(meta.error)) return null
    return <ErrorWrapper>{meta.error}</ErrorWrapper>
}

const ErrorWrapper: FC<PropsWithChildren> = ({children}) => (
    <Grid item={true} xs={12}>
        <Alert severity='error'>
            {children}
        </Alert>
    </Grid>
)
