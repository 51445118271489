import {Box, useMediaQuery} from '@mui/material'
import React, {FC, PropsWithChildren, useEffect} from 'react'

import {GraphQLErrors} from 'components/graphql-errors'
import {useToggle} from 'core/hooks'
import {ExpiredQuotesModal} from 'forms/quotes/expired/expired-quotes-modal'
import {useGetExpiredQuotesCountLazyQuery} from 'generated/graphql'

import {useUser} from './user'

export const ExpiredQuotesProvider: FC<PropsWithChildren> = ({children}) => {
    const print = useMediaQuery('print')
    const {user} = useUser()
    const [getExpiredQuotesCount, {data, error, refetch}] = useGetExpiredQuotesCountLazyQuery()
    const {open, setClose, setOpen} = useToggle()

    useEffect(() => {
        if (data && data.expiredQuotes.count > 0) setOpen()
        else setClose()
    }, [data])

    useEffect(() => {
        if (user && !print) getExpiredQuotesCount()
    }, [user])

    if (print) return <>{children}</>
    if (!user) return <>{children}</>

    return (
        <>
            {error && (
                <Box mb={4}>
                    <GraphQLErrors error={error} refetch={refetch} />
                </Box>
            )}
            <ExpiredQuotesModal open={open} onClose={setClose} expiredAmount={data?.expiredQuotes.count} />
            {children}
        </>
    )
}
