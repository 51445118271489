import {FC} from 'react';

import {Autocomplete, AutocompletePublicProps} from 'core/components/forms';
import {TryAndBuyTryAndBuyStatusChoices} from 'generated/graphql';

export const TryAndBuyStatusAutocomplete: FC<AutocompletePublicProps> = props => {
    const options = [
        {name: 'Expirado', id: TryAndBuyTryAndBuyStatusChoices.Expired.toLowerCase()},
        {name: 'Processo de aprovação do coordenador', id: TryAndBuyTryAndBuyStatusChoices.InCoordinatorApprovalProcess.toLowerCase()},
        {name: 'Demonstração', id: TryAndBuyTryAndBuyStatusChoices.InDemostration.toLowerCase()},
        {name: 'Processo de aprovação do gerente', id: TryAndBuyTryAndBuyStatusChoices.InManagerApprovalProcess.toLowerCase()},
        {name: 'Em processo de renovação', id: TryAndBuyTryAndBuyStatusChoices.InRenovationProcess.toLowerCase()},
        {name: 'Em processo de retorno', id: TryAndBuyTryAndBuyStatusChoices.InReturnProcess.toLowerCase()},
        {name: 'Em processo de venda', id: TryAndBuyTryAndBuyStatusChoices.InSaleProcess.toLowerCase()},
        {name: 'Vendido', id: TryAndBuyTryAndBuyStatusChoices.Sold.toLowerCase()},
        {name: 'Rejeitado', id: TryAndBuyTryAndBuyStatusChoices.Rejected.toLowerCase()},
        {name: 'Devolvido', id: TryAndBuyTryAndBuyStatusChoices.Returned.toLowerCase()},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='id'
            labelKey='name'
            label='Status do try & buy'
            {...props}
        />
    );
};
