import {Theme} from '@mui/material/styles'
import React, {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import * as settings from 'settings'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        maxWidth: '130px',
    }
}))

export const AgcoLogo: FC = () => {
    const {classes} = useStyles()
    const image = {
        agco: require('components/layout/assets/agco-logo.png'),
    }
    return (
        <div>
            <img src={image.agco} alt={settings.SITE_NAME} className={classes.root} />
        </div>
    )
}
