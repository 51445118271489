import getConfig from 'next/config'
import {Router} from 'next/router'
import {FC, useEffect} from 'react'

const {gaTrackingId} = getConfig().publicRuntimeConfig

export const GoogleAnalytics: FC = () => {
    return (
        <>
            <script
                async={true}
                src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gaTrackingId}');
                `,
                }}
            />
        </>
    )
}

interface PageViewTrackerProps {
    userId?: string
}

export const PageViewTracker: FC<PageViewTrackerProps> = ({userId}) => {
    useEffect(() => {
        Router.events.on('routeChangeComplete', url => {
            setTimeout(() => {
                // Wait until next tick so html title is updated and tracked correctly.
                ;(window as any).gtag('config', gaTrackingId, {page_path: url})
            }, 0)
        })
    }, [])

    useEffect(() => {
        ;(window as any).gtag('set', {user_id: userId})
    }, [userId])

    return null
}
