import {useGetRuntimeSettingsQuery} from 'generated/graphql';
import {ENVIRONMENT} from 'settings';

export interface Hash<T> {
    [key: string]: T;
};

export const getHash = (items: any[], key: string): Hash<any> => {
    return items.reduce((hash: Hash<any>, item: any) => {
        hash[item[key]] = item;
        return hash;
    }, {} as any);
};

export const useGetTaxIdLabel = () => {
    const {data} = useGetRuntimeSettingsQuery({fetchPolicy: 'cache-first'});
    return data?.runtimeSettings.taxIdLabel || 'Tax ID';
};

export const download = (dataurl: string[]) => {
    if (dataurl.length > 0) {
        dataurl.forEach(async url => {
            const response = await fetch(`/admin/invoice/`, {method: 'POST', credentials: 'same-origin', body: JSON.stringify({url})});
            const blob = await response.blob();
            const urlBlob = window.URL.createObjectURL(new Blob([blob]));

            const fileExtension = '.' + response.headers.get('Content-Type')?.split('/')[1] || '';
            const fileName = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1] || 'file' + fileExtension;
            const link = document.createElement('a');

            link.href = urlBlob;
            link.setAttribute('download', fileName);
            link.click();
        });
    }
};

export const fileDownload = async (url: string) => {
    const response = await fetch(`/admin/invoice/`, {method: 'POST', credentials: 'same-origin', body: JSON.stringify({url})});
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(new Blob([blob]));

    const fileExtension = '.' + response.headers.get('Content-Type')?.split('/')[1] || '';
    const fileName = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1] || 'file' + fileExtension;
    const link = document.createElement('a');

    link.href = urlBlob;
    link.setAttribute('download', fileName);
    link.click();
}

export const encodeToB64 = (object: any) => {
    return Buffer.from(JSON.stringify(object)).toString('base64');
};

export const decodeFromB64 = (value: string) => {
    return JSON.parse(Buffer.from(value, 'base64').toString());
};

export const convertUrlToFile = async (url: string) => {
    const type = url.split('?')[0].split('.')[1];
    const filename = url.split('/').pop()?.split('?')[0] || `file.${type}`;
    const response = await fetch(ENVIRONMENT === 'development' ? `${window.location.protocol}//${window.location.host}${url}` : url);
    const blob = await response.blob();
    return new File([blob], filename);
};

export const percentageToMultiplier = (percentage: number, operation: '+' | '-') => {
    return operation === '+' ? 1 + (percentage / 100) : 1 - (percentage / 100);
};

export const multiplierToPercentage = (multiplier: number, opration: '+' | '-') => {
    return opration === '+' ? (multiplier - 1) * 100 : (1 - multiplier) * 100;
};

export const roundTo = (value: number, precision: number) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

export const getValue = (row: any, key: string) => {
    let value = row;
    const keyPath = key.split('.');
    while (value && keyPath.length) {
        value = value[keyPath.shift() as string];
    }
    return value;
};
