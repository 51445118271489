import {Link as MUILink, LinkProps as MuiLinkProps} from '@mui/material'
import NextLink from 'next/link'
import React, {FC} from 'react'

interface LinkProps extends Pick<MuiLinkProps, 'underline'> {
    href: string
    as?: string
    children: React.ReactNode
}

export const Link: FC<React.PropsWithChildren<LinkProps>> = ({href, as, children, ...props}) => (
    <NextLink href={href} as={as} passHref={true} style={{color: 'inherit', textDecoration: 'none'}}>
        <MUILink component={'span'} color='inherit' underline='none' {...props}>{children}</MUILink>
    </NextLink>
)
