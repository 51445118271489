import {Button} from '@mui/material'
import {Formik, FormikHelpers} from 'formik'
import React, {FC, useState} from 'react'

import {AccountAutocomplete, AccountAutocompleteWithCreate, ContactTypeAutocomplete} from 'components/autocompletes'
import {Form, FormActions, FormErrors, SubmitButton, TextInput} from 'core/components/forms'
import {CreateContactMutationInput, UpdateContactMutationInput} from 'generated/graphql'

type Values = CreateContactMutationInput | UpdateContactMutationInput

interface ContactFormProps {
    initialValues?: Partial<Values>
    onSubmit(values: Values, actions: FormikHelpers<Values>): void
    onCancel?(): void
}

export const ContactForm: FC<ContactFormProps> = ({initialValues, onSubmit, onCancel}) => {
    const [disableAccount] = useState(() => Boolean(initialValues?.account))
    const defaultInitialValues: Values = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        mobilePhone: '',
        type: '',
        account: '',
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{...defaultInitialValues, ...(initialValues || {})}}>
            <Form withBackdrop={true}>
                <TextInput name='firstName' label='Nome' />
                <TextInput name='lastName' label='Sobrenome' />
                <TextInput name='email' label='Email' inputMode='email' />
                <ContactTypeAutocomplete name='type' />
                <TextInput name='mobilePhone' label='Telefone celular' inputMode='tel' />
                <TextInput name='phone' label='Telefone (fixo)' inputMode='tel' />
                {disableAccount
                    ? <AccountAutocomplete name='account' disabled={disableAccount} />
                    : <AccountAutocompleteWithCreate name='account' disabled={disableAccount} gridProps={{xs: 6}} />
                }
                <FormActions>
                    <SubmitButton label='Salvar' submittingLabel='Salvando...' />
                    {onCancel && <Button onClick={onCancel}>Cancelar</Button>}
                    <FormErrors />
                </FormActions>
            </Form>
        </Formik>
    )
}
