import {
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
    Button,
    CircularProgress,
    createFilterOptions,
    Grid,
    TextField,
} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {useEffect, useMemo} from 'react';

import {FormStatus, setFilterFieldValue} from 'core/components/forms';
import {useMobile} from 'core/hooks';
import {isString} from 'guards';
import {Only} from 'types';

import {defaultGridProps, FormChild} from './form';

type BaseAutocompleteMultipleProps<T extends object> = {
    valueKey: Only<T, string>;
    labelKey: Only<T, string>;
};

export type AutocompleteMultiplePublicProps<T extends object = any> = FormChild & {
    name: string;
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    submitOnSelect?: boolean;
    disableClearable?: boolean;
    filterOptions?: (option: T) => boolean;
} & Pick<MUIAutocompleteProps<T, true, false, false>, 'limitTags' | 'size'>;

type AutocompleteMultipleProps<T extends object> = FormChild
    & BaseAutocompleteMultipleProps<T>
    & AutocompleteMultiplePublicProps
    & Pick<MUIAutocompleteProps<T, true, false, false>, 'options' | 'renderOption'>;

const ALL_KEY = '__all__';

export function AutocompleteMultiple<T extends object>(props: AutocompleteMultipleProps<T>) {
    const {
        name,
        label,
        disabled,
        loading,
        submitOnSelect,
        options,
        disableClearable,
        valueKey,
        labelKey,
        gridProps,
        renderOption,
        limitTags,
        filterOptions,
        size,
    } = props;
    const isMobile = useMobile();
    const filteredOptions = useMemo(() => filterOptions && options.filter(filterOptions) || options, [filterOptions, options]);
    const {isSubmitting, setFieldValue, status, submitForm} = useFormikContext();
    const [field, meta] = useField<string[]>(name);
    const value = options.filter(option => {
        const value = option[valueKey];
        return isString(value) && (field.value || []).includes(value);
    }
    ) || [];

    // All props will trigger a re-render.
    const memoDeps = Object.keys(props)
        .sort()
        .map(key => (props as any)[key])
        .concat([value, isSubmitting, filteredOptions, isMobile]);

    useEffect(() => {
        if (status === FormStatus.filters) {
            setFilterFieldValue(name, {
                type: 'array',
                value: value.map(valueSelected => ({
                    value: valueSelected?.[valueKey],
                    label: `${label}: ${valueSelected?.[labelKey]}`,
                    name,
                    handleDelete: () => {
                        setFieldValue(
                            name,
                            value.filter(option => option[valueKey] !== valueSelected?.[valueKey])
                                .map(option => option[valueKey]) || [],
                            true,
                        );
                        submitForm();
                    }
                }))
            }, setFieldValue);
        }
    }, [field.value, label, status]);

    const component = useMemo(() => (
        <MUIAutocomplete<T, true, typeof disableClearable, false>
            value={value}
            options={[({[valueKey]: ALL_KEY, [labelKey]: 'Selecionar tudo'} as T), ...filteredOptions]}
            getOptionLabel={option => option[labelKey] as any}
            isOptionEqualToValue={(option, selected) => option[valueKey] === selected[valueKey]}
            filterOptions={createFilterOptions({stringify: option => option[labelKey] as string})}
            renderOption={(props, option, state) => {
                const value = option[valueKey];
                if (isString(value) && value === ALL_KEY) {
                    return (
                        <li {...props}>
                            <Button variant='outlined' color='primary' fullWidth={true}>
                                {option[labelKey] as any}
                            </Button>
                        </li>
                    );
                }
                if (renderOption) return renderOption(props, option, state);
                return <li {...props}>{option[labelKey] as any}</li>;
            }}
            multiple={true}
            blurOnSelect={true}
            openOnFocus={true}
            disableClearable={disableClearable}
            disabled={isSubmitting || disabled}
            loading={loading}
            limitTags={limitTags}
            size={size}
            onChange={(_event: any, values: T[]) => {
                // When this issue is merged we can pass shouldValidate
                // directly to helpers.setValue and helpers.setTouched.
                // https://github.com/jaredpalmer/formik/pull/2371
                if (values.some(value => {
                    const v = value[valueKey];
                    return isString(v) && v === ALL_KEY;
                })) {
                    setFieldValue(name, filteredOptions.map(option => option[valueKey]), true);
                } else {
                    setFieldValue(name, values.map(value => value[valueKey]), true);
                }
                if (submitOnSelect) submitForm();
            }}
            onBlur={() => {
                // FIXME: The error is persisted and you have to trigger the blur or change event again
                // to actually clear the previous error.
                // helpers.setTouched(true, true);
                // setFieldTouched(name, true, true);
            }}
            renderInput={params => (
                <TextField
                    variant='outlined'
                    label={label}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...params}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: isMobile ? true : undefined,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress size={20} color='primary' />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    ), memoDeps);

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
}

AutocompleteMultiple.defaultProps = {
    gridProps: defaultGridProps,
};
