import {ApolloProvider} from '@apollo/client'
import {CacheProvider, EmotionCache} from '@emotion/react'
import {ThemeProvider, Theme, StyledEngineProvider, CssBaseline} from '@mui/material'
import * as Sentry from '@sentry/node'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {NextPageContext} from 'next'
import App, {AppContext, AppProps} from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {SnackbarProvider} from 'notistack'
import numbro from 'numbro'
import {useEffect} from 'react'

import {apolloClient} from 'api'
import Layout from 'components/layout'
import {PageViewTrackerWithUserId} from 'components/page-view-tracker'
import {SetSentryUser} from 'components/set-sentry-user'
import {ExpiredQuotesProvider} from 'context/expired-quotes'
import {GeneralNoticeProvider} from 'context/general_notice'
import {UserProvider} from 'context/user'
import createEmotionCache from 'create-emotion-cache'
import * as settings from 'settings'
import {theme} from 'theme'

const {publicRuntimeConfig} = getConfig()

Sentry.init({
    dsn: publicRuntimeConfig.sentryPublicDSN,
    release: publicRuntimeConfig.version,
    environment: publicRuntimeConfig.environment,
    debug: publicRuntimeConfig.environment === 'development',
})

export type CustomAppProps = AppProps & {
    emotionCache?: EmotionCache
    err?: Error
}

dayjs.locale(settings.LANGUAGE_CODE.split('-')[0])
dayjs.extend(utc)
dayjs.extend(weekOfYear)
dayjs.extend(isSameOrBefore)
numbro.setLanguage('pt-br')
const clientSideEmotionCache = createEmotionCache()

const CustomApp = ({Component, pageProps, err, emotionCache = clientSideEmotionCache}: CustomAppProps) => {
    const router = useRouter()
    // useEffect(() => {
    //     // Delete the server-side injected CSS.
    //     const jssStyles = document.querySelector('#jss-server-side')
    //     if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles)
    // })

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const modifiedPageProps = {...pageProps, err}

    useEffect(() => {
        const handler = (nextPath: string) => {
            localStorage.setItem('prevPath', router.asPath)
            localStorage.setItem('nextPath', nextPath)
        }
        router.events.on('beforeHistoryChange', handler)
        return () => router.events.off('beforeHistoryChange', handler)
    })

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <title>{settings.SITE_NAME}</title>
                <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' />
            </Head>
            <StyledEngineProvider injectFirst={true}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <ApolloProvider client={apolloClient}>
                            <UserProvider>
                                <CssBaseline />
                                <Layout>
                                    <GeneralNoticeProvider>
                                        <ExpiredQuotesProvider>
                                            <Component {...modifiedPageProps} />
                                        </ExpiredQuotesProvider>
                                    </GeneralNoticeProvider>
                                </Layout>
                                <PageViewTrackerWithUserId />
                                <SetSentryUser />
                            </UserProvider>
                        </ApolloProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
    )
}

CustomApp.getInitialProps = async (appContext: NextPageContext & AppContext) => {
    const appProps = await App.getInitialProps(appContext)
    return {...appProps}
}

export default CustomApp
