import CloseIcon from '@mui/icons-material/Close'
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from '@mui/material'
import {FormikHelpers, useFormikContext} from 'formik'
import {useSnackbar} from 'notistack'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import {setErrors} from 'core/components/forms'
import {SeederForm, SeederFormValues} from 'forms/seeders/seeder-form'
import {CreateSeederMutationInput, GetSeederOptionsDocument, useCreateSeederMutation} from 'generated/graphql'

interface SeederDialogFormProps {
    open: boolean
    seederFieldName?: string
    accountId?: string | null
    toggle: () => void
}

export const SeederDialogForm: FC<SeederDialogFormProps> = ({
    open,
    toggle,
    accountId,
    seederFieldName = 'seeder'
}) => {
    const {classes} = useStyles()
    const [createSeeder] = useCreateSeederMutation()
    const {enqueueSnackbar} = useSnackbar()
    const {setFieldValue} = useFormikContext()

    const handleSubmit = async (
        values: SeederFormValues,
        actions: FormikHelpers<SeederFormValues>,
    ) => {
        const {data} = await createSeeder({
            variables: {input: values as CreateSeederMutationInput},
            refetchQueries: [
                {query: GetSeederOptionsDocument, variables: {accountId: accountId}}
            ],
        })
        if (data?.createSeeder?.errors?.length === 0 && data.createSeeder.seeder) {
            setFieldValue(seederFieldName, data.createSeeder.seeder.id, true)
            enqueueSnackbar('Plantadeira criada!', {variant: 'success'})
            toggle()
        } else {
            setErrors(data?.createSeeder?.errors, actions)
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            onClose={toggle}>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container={true} justifyContent='flex-end'>
                    <IconButton onClick={toggle} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.dialogContent} item={true} xs={12}>
                    {accountId && (
                        <SeederForm
                            initialValues={{
                                account: accountId,
                            }}
                            onSubmit={handleSubmit}
                            onCancel={toggle}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: 0,
    },

    dialogContent: {
        padding: theme.spacing(1),
    }
}))
