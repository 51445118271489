import {createTheme} from '@mui/material/styles'
import {VictoryTheme} from 'victory'

const defaultTheme = createTheme({})

export const theme = createTheme({
    shape: {
        borderRadius: 6,
    },
    palette: {
        primary: {
            main: '#00966C',
            light: '#00966C21',
        },
        secondary: {
            main: '#ED8B00',
        },
        error: {
            main: '#B0001F',
            light: '#FDECEA',
        },
        warning: {
            ...defaultTheme.palette.warning,
            main: '#E3813D',
            light: '#E3813D',
        },
        background: {
            default: '#EDEDED',
        },
    },
    components: {
        // Migration guide https://mui.com/material-ui/migration/v5-component-changes/#update-body-font-size
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: `${defaultTheme.spacing(2)}px ${defaultTheme.spacing(3)}px`,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: `1px solid ${defaultTheme.palette.divider}`,
                    boxShadow: 'none',
                    '@media print': {
                        border: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '@media print': {
                        padding: 0,
                    },
                },
                content: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&.Mui-expanded': {
                        margin: 0,
                        '@media print': {
                            minHeight: 'unset',
                        },
                    },
                },
                expandIconWrapper: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    '@media print': {
                        display: 'none',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                    '@media print': {
                        padding: 0,
                    },
                },
            },
        },
        MuiAccordionActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                    justifyContent: 'flex-start',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child > td': {
                        borderBottom: `0 !important`,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    '@media print': {
                        lineHeight: '0.9rem',
                    },
                },
                sizeSmall: {
                    '@media print': {
                        padding: `3px 10px 3px 10px`,
                    },
                },
                stickyHeader: {
                    backgroundColor: defaultTheme.palette.background.paper,
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#00966C21',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                sizeSmall: {
                    [defaultTheme.breakpoints.only('xs')]: {
                        fontSize: '.725rem',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1em',
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
            },
        },
    },
})

type VictoryThemeDefinition = typeof VictoryTheme.material
export const victoryTheme: VictoryThemeDefinition = {
    ...VictoryTheme.material,
    axis: {
        ...VictoryTheme.material.axis,
        style: {
            ...VictoryTheme.material.axis?.style,
            axis: {
                ...VictoryTheme.material.axis?.style?.axis,
                stroke: theme.palette.grey[300],
            },
            grid: {
                ...VictoryTheme.material.axis?.style?.grid,
                stroke: theme.palette.grey[300],
            },
            ticks: {
                ...VictoryTheme.material.axis?.style?.ticks,
                stroke: theme.palette.grey[300],
            },
            tickLabels: {
                ...VictoryTheme.material.axis?.style?.tickLabels,
                fontFamily: theme.typography.fontFamily,
                stroke: theme.palette.grey[500],
            },
        },
    },
    line: {
        ...VictoryTheme.material.line,
        style: {
            ...VictoryTheme.material.line?.style,
            data: {
                ...VictoryTheme.material.line?.style?.data,
                stroke: theme.palette.primary.main,
            },
        },
    },
}
