import {Menu as MenuIcon} from '@mui/icons-material'
import {
    AppBar,
    Drawer,
    IconButton,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useMediaQuery,
    useScrollTrigger,
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import router from 'next/router'
import React, {createContext, FC, useContext, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {Link} from 'components/link'
import {AgcoLogo} from 'components/quotes/agco-logo'
import {useUser} from 'context/user'
import {formatDateTime} from 'core/locale'
import * as settings from 'settings'

import AppMenu from './app-menu'
import UserMenu from './user-menu'

const drawerWidth = 310
const minDrawerWidth = 72

export const MAIN_MOBILE_PADDING = (theme: Theme) => theme.spacing(1.5)

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles<{elevateAppBar?: boolean, height?: number}>()((theme, props) => ({
    root: {
        display: 'flex',
    },

    main: {
        display: 'block',
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.only('xs')]: {
            padding: MAIN_MOBILE_PADDING(theme),
        },
    },

    mainWithSidebarHover: {
        marginLeft: minDrawerWidth,
        display: 'block',
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.only('xs')]: {
            padding: MAIN_MOBILE_PADDING(theme),
        },
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#FFF',
        background: '#3F4443',
        borderBottom: `${props.elevateAppBar ? 0 : 1}px solid ${theme.palette.divider}`,
    },

    menuButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.background.paper
    },

    logo: {
        flexGrow: 1,
        display: 'flex',
        marginTop: 4, // Adjust vertical alignment
        alignItems: 'center',
        '& img': {
            width: 'auto',
            height: props.height,
            [theme.breakpoints.down('md')]: {
                height: props.height || 20,
            }
        },
    },

    drawerPaper: {
        width: drawerWidth,
    },

    // toolbarSpacer: {
    //     ...theme.mixins.toolbar,
    // },

    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },

    printHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    drawer: {
        width: drawerWidth,
        paddingLeft: 0,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    drawerOpenedWithHover: {
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerClosedWithHover: {
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        minWidth: minDrawerWidth,
        maxWidth: minDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    warning: {
        color: '#E3813D',
    },

    warningAppBar: {
        background: '#E3813D',
        height: theme.spacing(1),
        border: 0,
    }
}))

interface LayoutProps {
    children: React.ReactNode
}

interface LogoProps {
    color: 'grey' | 'white'
    height: number
}

const Logo: FC<LogoProps> = ({color, height}) => {
    const {classes} = useStyles({height})
    const images = {
        grey: require('./assets/logo-horizontal-grey-new.svg'),
        white: require('./assets/logo-horizontal-white-new.svg'),
    }
    return (
        <div className={classes.logo}>
            <Link href='/'>
                <img src={images[color]} alt={settings.SITE_NAME} />
            </Link>
            {settings.ENVIRONMENT !== 'production-br' && (
                <Typography className={classes.warning}>
                    {settings.ENVIRONMENT || 'DEVELOPMENT'}
                </Typography>
            )}
        </div>
    )
}

type MenuContextType = {
    drawerOpenedWithMenuIcon: boolean,
    drawerOpenedWithlHover: boolean
    setDrawerOpenedWithMenuIcon: (drawerOpenedWithMenuIcon: boolean) => void,
}

const MenuContext = createContext<MenuContextType>({
    drawerOpenedWithMenuIcon: false,
    drawerOpenedWithlHover: false,
    setDrawerOpenedWithMenuIcon: () => {}
})

export const useMenu = () => useContext(MenuContext)

const Layout: FC<LayoutProps> = ({children}) => {
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const [drawerOpenedWithMenuIcon, setDrawerOpenedWithMenuIcon] = useState(false)
    const [drawerOpenedWithlHover, setDrawerOpenedWithHover] = useState(false)
    const elevateAppBar = useScrollTrigger({disableHysteresis: true, threshold: 0})
    const print = useMediaQuery('print')
    const {classes, cx} = useStyles({elevateAppBar})
    const agco = new URLSearchParams(window.location.search).get('agco')
    const {user} = useUser()
    const inHome = router.pathname === '/'

    if (print) {
        return (
            <>
                <div className={classes.printHeader}>
                    <Logo color='grey' height={print && 40} />
                    {agco ? <AgcoLogo /> : formatDateTime()}
                </div>
                {children}
            </>
        )
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} elevation={elevateAppBar ? 4 : 0}>
                <Toolbar>
                    {user && !inHome && (
                        <IconButton
                            className={classes.menuButton}
                            edge='start'
                            color='primary'
                            onClick={() => setDrawerOpenedWithMenuIcon(!drawerOpenedWithMenuIcon)}
                            size='large'>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Logo color='white' height={40} />
                    <UserMenu />
                </Toolbar>
                {settings.ENVIRONMENT !== 'production-br' && <AppBar className={classes.warningAppBar} position='sticky'></AppBar>}
            </AppBar>
            <MenuContext.Provider
                value={{
                    drawerOpenedWithMenuIcon: drawerOpenedWithMenuIcon,
                    setDrawerOpenedWithMenuIcon,
                    drawerOpenedWithlHover: drawerOpenedWithlHover,
                }}>
                {user && !inHome &&
                    <>{isSm ?
                        <SwipeableDrawer
                            className={classes.drawer}
                            classes={{paper: classes.drawerPaper}}
                            keepMounted={true}
                            open={drawerOpenedWithMenuIcon}
                            onOpen={() => setDrawerOpenedWithMenuIcon(true)}
                            onClose={() => setDrawerOpenedWithMenuIcon(false)}>
                            <AppMenu />
                        </SwipeableDrawer>
                        :
                        <Drawer
                            open={true}
                            variant='persistent'
                            className={cx(classes.drawer, {
                                [classes.drawerOpen]: drawerOpenedWithMenuIcon,
                                [classes.drawerClose]: !drawerOpenedWithMenuIcon,
                                [classes.drawerOpenedWithHover]: !drawerOpenedWithMenuIcon && drawerOpenedWithlHover,
                                [classes.drawerClosedWithHover]: !drawerOpenedWithMenuIcon && !drawerOpenedWithlHover,
                            })}
                            classes={{
                                paper: cx({
                                    [classes.drawerOpen]: drawerOpenedWithMenuIcon,
                                    [classes.drawerClose]: !drawerOpenedWithMenuIcon,
                                    [classes.drawerOpenedWithHover]: !drawerOpenedWithMenuIcon && drawerOpenedWithlHover,
                                    [classes.drawerClosedWithHover]: !drawerOpenedWithMenuIcon && !drawerOpenedWithlHover,
                                }),
                            }}
                            onMouseMove={!drawerOpenedWithMenuIcon ? () => setDrawerOpenedWithHover(true) : undefined}
                            onMouseLeave={!drawerOpenedWithMenuIcon ? () => setDrawerOpenedWithHover(false) : undefined}>
                            <Toolbar />
                            <AppMenu />
                        </Drawer>
                    }</>
                }
            </MenuContext.Provider>
            {isSm ?
                <main className={classes.main}>
                    <Toolbar />
                    {children}
                </main>
                :
                <main className={drawerOpenedWithMenuIcon ? classes.main : classes.mainWithSidebarHover}>
                    <Toolbar />
                    {children}
                </main>
            }
        </div>
    );
}

export default Layout
