import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography} from '@mui/material'
import React, {FC, useEffect} from 'react'

import {GraphQLErrors} from 'components/graphql-errors'
import {useToggle} from 'core/hooks'
import {useGetExpiredQuotesLazyQuery} from 'generated/graphql'

import {ExpiredQuotesForm} from './expired-quotes-form'

interface ExpiredQuotesModalProps {
    open: boolean
    onClose: () => void
    expiredAmount?: number
}

export const ExpiredQuotesModal: FC<ExpiredQuotesModalProps> = ({open, onClose, expiredAmount}) => {
    const [getExpiredQuotes, {data, error, loading, refetch}] = useGetExpiredQuotesLazyQuery()
    const quotes = data?.expiredQuotes.items

    const {open: confirmOpen, toggle: toggleConfirmOpen} = useToggle()
    const {open: formOpen, toggle: toggleForm} = useToggle()

    useEffect(() => {
        if (!formOpen) return
        getExpiredQuotes()
    }, [formOpen])

    const onSubmit = () => onClose()
    const onCancel = () => onClose()

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            fullWidth={formOpen}
            onClose={onClose}>
            <DialogContent>
                <Grid
                    container={true}
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing={2}>
                    <Grid item={true} xs={12}>
                        <Typography variant='h6'>
                            Cotações expiradas
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        {!confirmOpen && (
                            <>
                                <Typography variant='body2'>
                                    Você tem {expiredAmount} cotação (s) expirada (s).
                                </Typography>
                                <Typography variant='body2'>
                                    Selecione um motivo para cada um para fechá-lo.
                                </Typography>
                            </>
                        )}
                        {loading && <CircularProgress color='primary' />}
                        {error && <GraphQLErrors error={error} refetch={refetch} />}
                        {formOpen && quotes && <ExpiredQuotesForm quotes={quotes} onSubmit={onSubmit} onCancel={onCancel} />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {!confirmOpen && (
                    <>
                        <Button onClick={onClose}>
                            Fechar
                        </Button>
                        <Button
                            onClick={() => {
                                toggleConfirmOpen()
                                toggleForm()
                            }}
                            color='primary'>
                            Continuar
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
