import {Grid} from '@mui/material'
import {DatePicker as MUIDatePicker, DatePickerProps as MUIDatePickerProps} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import {useField, useFormikContext} from 'formik'
import React, {FC, useEffect} from 'react'

import {FormStatus, setFilterFieldValue} from 'core/components/forms'
import {TextInputProps} from 'core/components/forms/text-input'
import {useMobile} from 'core/hooks'
import * as settings from 'settings'

import {defaultGridProps, FormChild} from './form'

type BaseDatePickerProps = {
    name: string
    submitOnSelect?: boolean
    showTodayButton?: boolean
}

type DatePickerProps = FormChild
    & BaseDatePickerProps
    & Partial<Pick<MUIDatePickerProps<unknown>, 'className' | 'label' | 'disabled' | 'disableFuture'>>
    & Partial<Pick<TextInputProps, 'className' | 'size' | 'placeholder' | 'label' | 'disabled'>>

export const DatePicker: FC<DatePickerProps> = ({
    name,
    disabled,
    gridProps,
    submitOnSelect,
    showTodayButton,
    ...otherProps
}) => {
    const isMobile = useMobile()
    const {isSubmitting, setFieldValue, submitForm, status} = useFormikContext()
    const [field, meta] = useField<string>(name)

    if (field.value && field.value.length !== 10) {
        console.warn(`DatePicker value must be a date only ISO-8061 string (YYYY-MM-DD), received: ${field.value}`)
    }

    useEffect(() => {
        if (status === FormStatus.filters) {
            setFilterFieldValue(name, {
                type: 'single',
                value: {
                    value: field.value,
                    label: `${otherProps.label}: ${field.value}`,
                    name,
                    handleDelete: () => {
                        setFieldValue(name, '', true)
                        submitForm()
                    }

                }
            }, setFieldValue)
        }
    }, [field.value, otherProps.label, status])

    const component = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MUIDatePicker
                disabled={isSubmitting || disabled}
                format={settings.DATE_FORMAT}
                value={field.value ? dayjs(field.value) : null}
                slotProps={{
                    textField: {
                        size: otherProps.size,
                        fullWidth: true,
                        variant: 'outlined',
                        onBlur: field.onBlur,
                        error: meta.touched && Boolean(meta.error),
                        disabled: isSubmitting || disabled,
                        helperText: meta.touched && meta.error,
                        InputLabelProps: {shrink: isMobile ? true : undefined},
                    },
                }}
                onChange={date => {
                    const value = date ? dayjs(date).format('YYYY-MM-DD') : ''
                    setFieldValue(name, value, true)
                    if (submitOnSelect) submitForm()
                }}
                {...otherProps}
            />
        </LocalizationProvider>
    )
    if (!gridProps) return component

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    )
}

DatePicker.defaultProps = {
    gridProps: defaultGridProps,
}
