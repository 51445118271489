import {Button, Checkbox, FormControlLabel, Grid, Typography} from '@mui/material'
import {ArrayHelpers, FieldArray, Formik, FormikErrors, FormikHelpers, FormikProps, setIn} from 'formik'
import React, {ChangeEvent, FC} from 'react'

import {AccountAutocomplete, SeederBrandAutocomplete, SeederModelAutocomplete} from 'components/autocompletes'
import {Autocomplete, Form, FormActions, FormErrors, NumberInput, SubmitButton, TextInput} from 'core/components/forms'
import {
    CreateSeederMutationInput,
    ProductCategoryOptionFragment,
    SeedersSeederMeterTypeChoices,
    UpdateSeederMutationInput,
    useGetProductCategoriesEnabledForSeederQuery,
} from 'generated/graphql'
import {validateRequired} from 'utils/validators'

export type SeederFormValues = Omit<CreateSeederMutationInput | UpdateSeederMutationInput, 'rowCount' | 'year' | 'quantity' | 'installedProductCategories'>
    & {rowCount: number | null}
    & {installedProductCategories: string[]}
    & {year: number | null}
    & {quantity: number | null}

interface SeederFormProps {
    initialValues?: Partial<SeederFormValues>
    onSubmit(values: SeederFormValues, actions: FormikHelpers<SeederFormValues>): void
    onCancel?(): void
}

export const SeederForm: FC<SeederFormProps> = ({initialValues, onSubmit, onCancel}) => {
    const defaultInitialValues: SeederFormValues = {
        account: '',
        brand: '',
        model: '',
        year: null,
        rowCount: null,
        quantity: null,
        meterType: '',
        installedProductCategories: [],
        note: '',
    }
    const meterTypeOptions = [
        {label: 'Pneumático', value: SeedersSeederMeterTypeChoices.Pneumatic.toLowerCase()},
        {label: 'Mecânico', value: SeedersSeederMeterTypeChoices.Mechanic.toLowerCase()}
    ]
    const {data} = useGetProductCategoriesEnabledForSeederQuery()

    const createChangeHandler = (
        id: ProductCategoryOptionFragment['id'],
        categories: SeederFormValues['installedProductCategories'],
        helpers: ArrayHelpers,
    ) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.currentTarget.checked) return helpers.push(id)
            return helpers.remove(categories.indexOf(id))
        }
    }

    const renderCategory = (
        category: ProductCategoryOptionFragment,
        categories: SeederFormValues['installedProductCategories'],
        arrayHelpers: ArrayHelpers,
    ) => {
        const onChange = createChangeHandler(category.id, categories, arrayHelpers)
        const checked = categories.includes(category.id)
        const control = <Checkbox checked={checked} name={category.id} onChange={onChange} />
        return (
            <Grid key={category.id} item={true} xs={12} sm={4} md={3}>
                <FormControlLabel label={category.name} control={control} />
            </Grid>
        )
    }

    const handleSubmit = (values: SeederFormValues, actions: FormikHelpers<SeederFormValues>) => {
        onSubmit(values, actions)
    }

    const validate = (values: SeederFormValues) => {
        let errors: FormikErrors<SeederFormValues> = {};
        ['year', 'rowCount', 'brand', 'model', 'meterType', 'quantity'].forEach(field => {
            const result = validateRequired(values[field as keyof SeederFormValues])
            if (result !== true) errors = setIn(errors, field, result)
        })
        return errors
    }

    return (
        <Formik
            validate={validate}
            onSubmit={handleSubmit}
            initialValues={{...defaultInitialValues, ...(initialValues || {})}}>
            {(formikProps: FormikProps<SeederFormValues>) => {
                return (
                    <Form withBackdrop={true}>
                        <FormErrors />
                        <AccountAutocomplete name='account' disabled={true} />
                        <SeederBrandAutocomplete name='brand' />
                        <SeederModelAutocomplete name='model' seederBrandFieldName='brand' />
                        <NumberInput
                            name='year'
                            inputMode='numeric'
                            size='medium'
                            label='Ano'
                            gridProps={{md: 3, xs: 12}}
                        />
                        <NumberInput
                            name='rowCount'
                            inputMode='numeric'
                            size='medium'
                            label='Número de linhas'
                            gridProps={{md: 3, xs: 12}}
                        />
                        <NumberInput
                            name='quantity'
                            label='Quantidade'
                            inputMode='numeric'
                            size='medium'
                            gridProps={{md: 6, xs: 12}}
                        />
                        <Autocomplete
                            name='meterType'
                            label='Tipo de dosador'
                            valueKey='value'
                            labelKey='label'
                            options={meterTypeOptions}
                            gridProps={{md: 6, xs: 12}}
                        />
                        <TextInput name='note' label='Comentário' multiline={true} rows={4} gridProps={{xs: 12}} />
                        {!!data?.productCategoriesEnabledForSeeder.length && (
                            <Grid item={true} xs={12}>
                                <Typography variant='h6'>Possibilidade de venda de produto</Typography>
                                <FieldArray name='installedProductCategories'>
                                    {arrayHelpers => (
                                        <Grid container={true} spacing={1}>
                                            {data?.productCategoriesEnabledForSeeder.map(category => (
                                                renderCategory(
                                                    category,
                                                    formikProps.values.installedProductCategories,
                                                    arrayHelpers,
                                                )
                                            ))}
                                        </Grid>
                                    )}
                                </FieldArray>
                            </Grid>
                        )}
                        <FormActions>
                            <SubmitButton label='Salvar' submittingLabel='Salvando...' />
                            {onCancel && <Button onClick={onCancel}>Cancelar</Button>}
                        </FormActions>
                    </Form>
                )
            }}
        </Formik>
    )
}
