import {InputAdornment, MenuItem, TextField} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {Field, FieldProps, Formik, useField, useFormikContext} from 'formik'
import React, {FC, useEffect, useMemo} from 'react'
import {makeStyles} from 'tss-react/mui'

import * as settings from 'settings'

import {FormChild} from './form'
import {NumberInput} from './number-input'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: `${theme.spacing(1)} !important`,
        },
        '& > *:nth-child(1)': {
            width: '8rem',
        },
        '& > *:nth-child(2)': {
            width: '6rem',
        },
    }
}))

type MultiplierInputProps = FormChild & {
    name: string
    disabled?: boolean
}

type MultiplierInputValues = {
    operation: string
    percentage: number
}

const percentageToMultiplier = (values: MultiplierInputValues) => {
    if (values.operation === 'discount') return Number((1 - values.percentage / 100).toFixed(Number(settings.MAX_DECIMALS) + 2))
    return Number((1 + values.percentage / 100).toFixed(Number(settings.MAX_DECIMALS) + 2))
}

const multiplierToPercentage = (value: number | null) => {
    if (!value) return 0
    if (value > 1) return Number((value * 100 - 100).toFixed(Number(settings.MAX_DECIMALS)))
    return Number((100 - value * 100).toFixed(Number(settings.MAX_DECIMALS)))
}

const multiplierToOperation = (value: number | null) => {
    if (value && value > 1) return 'surcharge'
    return 'discount'
}

type SetValueInParentFormProps = {
    setValue(value: number): void
}

const SetValueInParentForm: FC<SetValueInParentFormProps> = ({setValue}) => {
    const {values} = useFormikContext<MultiplierInputValues>()
    useEffect(() => {
        setValue(percentageToMultiplier(values))
    }, [values])
    return null
}

export const MultiplierInput: FC<MultiplierInputProps> = ({name, disabled}) => {
    const {classes} = useStyles()
    const {isSubmitting} = useFormikContext()
    const [field, _meta, helpers] = useField<number | null>(name)

    const initialValues: MultiplierInputValues = useMemo(() => {
        return {
            operation: multiplierToOperation(field.value),
            percentage: multiplierToPercentage(field.value),
        }
    }, [field.value])

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => {}}>
            <div className={classes.root}>
                <SetValueInParentForm setValue={helpers.setValue} />
                <NumberInput
                    name='percentage'
                    inputMode='decimal'
                    size='small'
                    gridProps={false}
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                    disabled={disabled || isSubmitting}
                />
            </div>
        </Formik>
    )
}
