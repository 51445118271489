import {Link as MUILink, LinkProps} from '@mui/material'
import NextLink from 'next/link'
import React, {FC} from 'react'
import {UrlObject} from 'url'

interface TextLinkProps extends Omit<LinkProps<'span'>, 'href'> {
    href: string | UrlObject
    as?: string
    children: React.ReactNode
}

export const TextLink: FC<React.PropsWithChildren<TextLinkProps>> = ({href, as, children, ...rest}) => (
    <NextLink href={href} as={as} passHref={true} style={{color: 'inherit', textDecoration: 'none'}}>
        <MUILink component='span' color='inherit' underline='hover' {...rest}>{children}</MUILink>
    </NextLink>
)
