import {FC} from 'react';

import {Autocomplete, AutocompletePublicProps} from 'core/components/forms';
import {useGetCloseReasonsQuery} from 'generated/graphql';

export const CloseReasonAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetCloseReasonsQuery();

    return (
        <Autocomplete
            options={data?.closeReasons || []}
            loading={loading}
            valueKey='description'
            labelKey='description'
            label='Razão do não fechamento'
            {...props}
        />
    );
};
