import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material'
import React, {createContext, FC, PropsWithChildren, useContext, useEffect} from 'react'

import {GraphQLErrors} from 'components/graphql-errors'
import {useToggle} from 'core/hooks'
import {useGetRuntimeSettingsQuery} from 'generated/graphql'

const GeneralNoticeContext = createContext({
    generalNoticeContent: '',
})

export const GeneralNoticeProvider: FC<PropsWithChildren> = ({children}) => {
    const print = useMediaQuery('print')
    const {data, error, loading, refetch} = useGetRuntimeSettingsQuery()
    const {open, toggle} = useToggle()

    useEffect(() => {
        if (data && !!data.runtimeSettings.generalNoticeContent) toggle()
    }, [data])

    if (!data?.runtimeSettings.generalNoticeContent || print) {
        return <GeneralNoticeContext.Provider value={{generalNoticeContent: ''}}>{children}</GeneralNoticeContext.Provider>
    }

    const {generalNoticeContent, generalNoticeTitle} = data?.runtimeSettings

    return (
        <GeneralNoticeContext.Provider value={{generalNoticeContent: generalNoticeContent || ''}}>
            <>
                {error && <GraphQLErrors error={error} refetch={refetch} />}
                {loading
                    ? <CircularProgress color='primary' />
                    : <>
                        <Dialog open={open} maxWidth='lg' onClose={toggle}>
                            {generalNoticeTitle && <DialogTitle>{generalNoticeTitle}</DialogTitle>}
                            <DialogContent>{generalNoticeContent}</DialogContent>
                            <DialogActions><Button onClick={toggle}>Fechar</Button></DialogActions>
                        </Dialog>
                        {children}
                    </>
                }
            </>
        </GeneralNoticeContext.Provider>
    )
}

export const useGeneralNotice = () => useContext(GeneralNoticeContext)
