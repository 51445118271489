import CloseIcon from '@mui/icons-material/Close'
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from '@mui/material'
import {FormikHelpers, useFormikContext} from 'formik'
import {useSnackbar} from 'notistack'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import {setErrors} from 'core/components/forms'
import {ContactForm} from 'forms/contacts/contact-form'
import {GetContactOptionsDocument, useCreateContactMutation, CreateContactMutationInput} from 'generated/graphql'

interface ContactDialogFormProps {
    open: boolean
    contactFieldName?: string
    accountId?: string | null
    toggle: () => void
}

export const ContactDialogForm: FC<ContactDialogFormProps> = ({
    open,
    accountId,
    toggle,
    contactFieldName = 'contact',
}) => {
    const {classes} = useStyles()
    const [createContact, {error}] = useCreateContactMutation()
    const {enqueueSnackbar} = useSnackbar()
    const {setFieldValue} = useFormikContext()

    const handleSubmit = async (
        values: CreateContactMutationInput,
        actions: FormikHelpers<CreateContactMutationInput>,
    ) => {
        const {data} = await createContact({
            variables: {input: values},
            refetchQueries: [
                {query: GetContactOptionsDocument},
                {query: GetContactOptionsDocument, variables: {options: {account: [accountId]}}},
            ],
        })
        if (data?.createContact?.errors?.length === 0 && data.createContact.contact) {
            setFieldValue(contactFieldName, data.createContact.contact.id, true)
            enqueueSnackbar('Contato criado!', {variant: 'success'})
            toggle()
        } else {
            setErrors(data?.createContact?.errors, actions)
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            onClose={toggle}>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container={true} justifyContent='flex-end'>
                    <IconButton onClick={toggle} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.dialogContent} item={true} xs={12}>
                    {accountId && (
                        <ContactForm
                            initialValues={{
                                account: accountId,
                            }}
                            onSubmit={handleSubmit}
                            onCancel={toggle}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: 0,
    },

    dialogContent: {
        padding: theme.spacing(1),
    }
}))
