import {createTheme} from '@mui/material/styles'

import {theme as defaultTheme} from 'theme'

export const theme = createTheme({
    ...defaultTheme,
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                    background: defaultTheme.palette.primary.light,
                },
            },
        },
    },
})
