import CloseIcon from '@mui/icons-material/Close';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from '@mui/material';
import {FormikHelpers} from 'formik';
import {useSnackbar} from 'notistack';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

import {setErrors} from 'core/components/forms';
import {AccountForm, AccountFormProps} from 'forms/accounts/account-form';
import {
    AccountType,
    CreateAccountMutationInput,
    GetAccountOptionsDocument,
    GetAccountsDocument,
    useCreateAccountMutation,
} from 'generated/graphql';

interface AccountDialogFormProps {
    initialValues?: AccountFormProps['initialValues'];
    extraValidation?: AccountFormProps['extraValidation'];
    open: boolean;
    toggle: () => void;
    onSubmit: (value: Pick<AccountType, 'id'>) => void;
}

export const AccountDialogForm: FC<AccountDialogFormProps> = ({
    open,
    toggle,
    initialValues,
    onSubmit,
    extraValidation,
}) => {
    const {classes} = useStyles();
    const [createAccount] = useCreateAccountMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = async (
        values: CreateAccountMutationInput,
        actions: FormikHelpers<CreateAccountMutationInput>,
    ) => {
        const {data} = await createAccount({
            variables: {input: values},
            refetchQueries: [
                {query: GetAccountOptionsDocument},
                {query: GetAccountsDocument}
            ],
        });
        if (data?.createAccount?.errors?.length === 0 && data.createAccount.account) {
            toggle();
            onSubmit(data.createAccount.account);
            enqueueSnackbar('Conta criada!', {variant: 'success'});
        } else {
            setErrors(data?.createAccount?.errors, actions);
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            onClose={toggle}>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container={true} justifyContent='flex-end'>
                    <IconButton onClick={toggle} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid item={true} xs={12} className={classes.dialogContent}>
                    <AccountForm
                        onSubmit={handleSubmit}
                        onCancel={toggle}
                        initialValues={initialValues}
                        extraValidation={extraValidation}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: 0,
    },

    dialogContent: {
        padding: theme.spacing(1),
    }
}));
