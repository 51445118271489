import {SvgIcon, SvgIconProps} from '@mui/material'
import {FC} from 'react'

export const TryAndBuyIcon: FC<SvgIconProps> = ({...props}) => {
    return (
        <SvgIcon {...props} viewBox='0 0 285 285' width={285} height={285}>
            <path d='M132.953 48H74.025C68.4535 48.0201 63.1157 50.3524 59.1767 54.4879C55.2377 58.6233 53.0173 64.2261 53 70.0735V131.919C53.0154 137.77 55.2369 143.377 59.1793 147.515C63.1217 151.652 68.4642 153.984 74.0395 154H132.968C138.542 153.982 143.882 151.649 147.823 147.512C151.764 143.375 153.985 137.769 154 131.919V70.0735C153.994 64.2184 151.774 58.6052 147.828 54.4665C143.882 50.3278 138.532 48.002 132.953 48V48ZM145.575 70.0735H116.126V56.8416H132.953C136.298 56.8416 139.507 58.2352 141.873 60.7162C144.24 63.1972 145.572 66.5628 145.575 70.0735V70.0735ZM99.2914 56.8416H107.709V78.9074C107.663 80.0471 107.2 81.1244 106.416 81.914C105.632 82.7037 104.587 83.1446 103.5 83.1446C102.413 83.1446 101.368 82.7037 100.584 81.914C99.7997 81.1244 99.3365 80.0471 99.2914 78.9074V56.8416ZM74.0541 56.8416H90.8814V70.0887H61.4318C61.4318 66.5754 62.7617 63.2059 65.1288 60.7216C67.4959 58.2373 70.7065 56.8416 74.0541 56.8416ZM132.982 145.151H74.0541C70.7065 145.151 67.4959 143.755 65.1288 141.271C62.7617 138.786 61.4318 135.417 61.4318 131.904V78.9074H90.8814C90.8814 82.4208 92.2112 85.7903 94.5784 88.2746C96.9455 90.7589 100.156 92.1546 103.504 92.1546C106.851 92.1546 110.062 90.7589 112.429 88.2746C114.796 85.7903 116.126 82.4208 116.126 78.9074H145.575V131.888C145.575 135.402 144.246 138.771 141.878 141.255C139.511 143.74 136.301 145.135 132.953 145.135L132.982 145.151Z' />
            <path d='M80.978 131H73.022C71.9053 131 71 132.313 71 133.934V134.066C71 135.687 71.9053 137 73.022 137H80.978C82.0947 137 83 135.687 83 134.066V133.934C83 132.313 82.0947 131 80.978 131Z' />
            <path d='M214.005 153.934C214.016 152.369 214.639 150.87 215.741 149.761C216.843 148.651 218.335 148.019 219.898 148C221.512 148.013 223.057 148.661 224.199 149.804C225.34 150.947 225.987 152.494 226 154.111C225.974 154.911 225.791 155.699 225.46 156.428C225.13 157.157 224.659 157.813 224.075 158.36C223.491 158.907 222.805 159.333 222.057 159.613C221.308 159.894 220.511 160.024 219.712 159.996C218.937 159.983 218.172 159.814 217.463 159.501C216.754 159.187 216.115 158.734 215.583 158.169C215.051 157.604 214.638 156.939 214.367 156.211C214.096 155.484 213.973 154.709 214.005 153.934Z' />
            <path d='M216.252 178C208.643 178.049 201.22 180.352 194.919 184.617C188.618 188.882 183.722 194.919 180.849 201.964C177.977 209.01 177.256 216.749 178.779 224.204C180.301 231.659 183.999 238.496 189.404 243.851C194.81 249.206 201.681 252.839 209.149 254.292C216.618 255.744 224.35 254.952 231.369 252.013C238.387 249.075 244.377 244.122 248.583 237.781C252.789 231.441 255.022 223.996 255 216.387C254.925 206.167 250.807 196.392 243.546 189.199C236.286 182.005 226.472 177.979 216.252 178ZM216.443 244.38C210.932 244.369 205.548 242.725 200.971 239.656C196.393 236.587 192.828 232.231 190.724 227.137C188.621 222.044 188.074 216.441 189.152 211.036C190.231 205.632 192.886 200.668 196.784 196.772C200.681 192.876 205.645 190.221 211.05 189.144C216.455 188.067 222.057 188.616 227.15 190.72C232.244 192.825 236.599 196.392 239.667 200.97C242.734 205.548 244.377 210.933 244.386 216.444C244.371 223.85 241.422 230.948 236.185 236.184C230.948 241.42 223.849 244.367 216.443 244.38V244.38Z' />
            <path d='M202.028 211.998C203.977 211.854 205.41 213.145 206.92 214.442C208.577 215.884 209.863 218.617 211.889 218.408C213.623 218.227 215.102 215.827 216.666 214.377C220.272 211.025 223.839 207.637 227.452 204.284C230.495 201.473 233.77 201.257 236.297 203.636C238.824 206.015 238.516 209.129 235.526 211.919C229.24 217.836 222.938 223.738 216.62 229.625C213.176 232.826 210.202 232.783 206.72 229.546C203.723 226.763 200.718 223.995 197.775 221.161C196.917 220.425 196.333 219.453 196.105 218.385C195.878 217.316 196.02 216.208 196.512 215.221C197.498 213.181 199.27 212.027 202.028 211.998Z' />
            <path d='M268.179 172.327C262.024 165.145 254.462 159.307 245.964 155.173C242.891 153.689 239.803 154.698 238.374 157.46C236.944 160.221 237.922 163.286 240.869 165.008C241.824 165.565 242.817 166.055 243.787 166.582C264.825 178.086 277.11 202.773 273.311 225.783C267.817 258.843 236.204 280.175 203.643 272.121C182.568 266.926 168.409 253.343 162.544 232.367C156.768 211.74 160.937 192.82 176.229 177.329C176.644 176.906 177.044 176.483 177.443 176.06C182.027 171.42 186.167 166.361 189.81 160.948C197.608 149.652 202.91 136.817 205.361 123.301C206.628 116.774 207.265 110.14 207.264 103.491C207.264 46.3383 160.871 0 103.643 0C46.4158 0 0 46.3011 0 103.454C0 160.607 46.4084 206.967 103.621 206.967C105.704 206.967 107.77 206.903 109.819 206.774H110.093C108.612 208.57 107.338 210.01 106.161 211.517C103.939 214.345 104.139 217.633 106.605 219.682C109.071 221.73 112.278 221.359 114.499 218.776C118.814 213.769 123.07 208.702 127.266 203.575C128.295 202.328 128.794 200.726 128.655 199.114C128.517 197.503 127.752 196.01 126.525 194.958C121.544 190.697 116.526 186.471 111.471 182.28C108.508 179.853 105.272 180.053 103.169 182.644C101.177 185.093 101.622 188.337 104.332 190.727C106.028 192.211 107.79 193.696 109.938 195.492C102.321 195.943 94.6775 195.47 87.1741 194.082C37.026 184.633 3.82849 135.17 14.2698 85.3357C24.7926 35.0562 73.7114 2.79826 123.563 14.0284C158.027 21.7848 181.472 43.1689 191.691 76.9335C202.058 111.292 194.653 142.488 169.246 168.445C152.954 185.093 146.704 204.815 150.029 227.505C156.302 270.318 201.91 296.259 242.106 280.204C252.211 276.218 261.212 269.853 268.346 261.647C275.481 253.44 280.54 243.633 283.097 233.054C285.654 222.474 285.634 211.434 283.037 200.864C280.441 190.294 275.345 180.506 268.179 172.327Z' />
        </SvgIcon>
    )
}
