import {Tooltip} from '@mui/material'
import {FC} from 'react'

import {Autocomplete, AutocompletePublicProps} from 'core/components/forms'
import {OpportunitiesOpportunityPaymentConditionChoices, OpportunityDetailFragment} from 'generated/graphql'

export type OpporunityMaturityDescriptionKeys = keyof Pick<OpportunityDetailFragment, 'agronomicDevelopment' | 'solutionTrust' | 'paymentCondition'>
export const useOpportunityMaturityTranslations = (): {
    [key in OpportunitiesOpportunityPaymentConditionChoices]: {
        label: string,
        description: {[key in OpporunityMaturityDescriptionKeys]: string}
    }
} => ({
    [OpportunitiesOpportunityPaymentConditionChoices.Low]: {
        label: 'Baixo',
        description: {
            'agronomicDevelopment': 'Cliente não conhece o problema agronômico.',
            'solutionTrust': 'Não confia na empresa e nem na solução.',
            'paymentCondition': 'Cliente não tem como pagar.',
        },
    },
    [OpportunitiesOpportunityPaymentConditionChoices.Medium]: {
        label: 'Médio',
        description: {
            'agronomicDevelopment': 'Conhece o problema agronômico mas não o tamanho do impacto.',
            'solutionTrust': 'Confia na solução e não na empresa/ confia na empresa e não na solução.',
            'paymentCondition': 'Cliente tem como pagar mas pode ter alguma restrição ou risco.',
        },
    },
    [OpportunitiesOpportunityPaymentConditionChoices.High]: {
        label: 'Alto',
        description: {
            'agronomicDevelopment': 'Conhece o problema agronômico e já quantificou o tamanho do impacto.',
            'solutionTrust': 'Confia que a solução vai funcionar e que o dealer tem condição de dar suporte.',
            'paymentCondition': 'Cliente tem como pagar, tem bom score no banco ou pode pagar à vista.',
        },
    },
})

export const OpportunityStatusChoiceAutocomplete: FC<AutocompletePublicProps & {descKey?: OpporunityMaturityDescriptionKeys}> = ({descKey, ...props}) => {
    const choices = useOpportunityMaturityTranslations()
    const options = Object.entries(choices).map(([key, value]) => ({value: key, label: value.label, description: descKey && value.description[descKey] || ''}))

    return <Autocomplete
        options={options}
        labelKey='label'
        valueKey='value'
        renderOption={(props, option: typeof options[0]) => (
            <Tooltip title={option.description} arrow={true} placement='left'><li {...props}>{option.label}</li></Tooltip>
        )}
        {...props}
           />
}
