export const validateRequired = (value: any): boolean | string => {
    if (!value || Array.isArray(value) && !value.length) return 'Este campo é obrigatório';
    return true;
}

export const validateQuantity = (value: number, min = 0, max?: number): boolean | string => {
    if (value < min) return `O valor não pode ser inferior a ${min}.`;
    if (max && value > max) return `O valor não pode ser maior que ${min}.`;
    return true;
};

export const compare = {
    'LT': (a : any, b : any) => a < b,
    'LTE': (a : any, b : any) => a <= b,
    'EQ': (a : any, b : any) => a === b,
    'GT': (a : any, b : any) => a > b,
    'GTE': (a : any, b : any) => a >= b,
};
