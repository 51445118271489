import {Card, CardContent, CardProps, Chip, ChipProps, Divider, Grid, Theme, Typography} from '@mui/material';
import router from 'next/router';
import {makeStyles} from 'tss-react/mui';
import {UrlObject} from 'url';

import {getValue} from 'utils';

type Base<T> = {
    key: string;
    notClickable?: boolean;
    format?(value: any): React.ReactNode;
    render?(row: T, indexRow: number, indexCol: number): React.ReactNode;
    theme?: Theme;
};
type Header<T> = Base<T>
    & {
        chipProps?: Pick<ChipProps, 'className' | 'size' | 'color' | 'style'>;
        color?: 'primary' | 'secondary' | 'tertiary';
        noChip?: boolean;
    };

type Content<T> = Base<T> & {label: string;};

export type AsyncCardProps<T extends object> = {
    row: T;
    indexRow: number;
    getRowLink?(row: T): {url: UrlObject | string, as?: UrlObject | string;};
    headers?: Header<T>[];
    contents: Content<T>[];
    moreOptions?: (row: T) => React.ReactNode;
    rootStyle?: CardProps['style'];
    noWrapText?: boolean;
};

export const AsyncCard = <T extends object>({
    row,
    indexRow,
    getRowLink,
    moreOptions,
    rootStyle,
    noWrapText,
    contents,
    headers,
}: AsyncCardProps<T>) => {
    const {classes} = useStyles();

    const renderValue = (target: Base<T>, index: number) => {
        const value = getValue(row, target.key) || '-';
        if (target.render) return target.render(row, indexRow, index);
        if (target.format) return target.format(value);
        return value;
    };

    const colorChip = {
        primary: {backgroundColor: '#2727274D'},
        secondary: {backgroundColor: '#F0F0F0'},
        tertiary: {backgroundColor: '#42CCA54D', color: '#02B985'}
    };

    return (
        <Card variant='outlined' className={classes.card} style={rootStyle}>
            {headers &&
                <Grid container={true} wrap='nowrap' className={classes.cardHeader}>
                    <Grid container={true} item={true} alignItems='center' spacing={1}>
                        {headers?.map(({color, chipProps, ...header}, index) => (
                            <Grid item={true} key={`${header.key}.${index}`}>
                                {header.noChip && renderValue(header, index)
                                    || (
                                        <Chip
                                            label={renderValue(header, index)}
                                            {...chipProps}
                                            style={color && color in colorChip ? {...colorChip[color], ...chipProps?.style} : chipProps?.style}
                                        />
                                    )}
                            </Grid>
                        ))}
                    </Grid>
                    {moreOptions && <Grid item={true}>{moreOptions(row)}</Grid>}
                </Grid>
            }
            {contents.map((content, index) => (
                <>
                    <CardContent
                        key={content.key}
                        onClick={getRowLink && (
                            () => {
                                const link = getRowLink(row);
                                router.push(link.url, link.as);
                            }
                        ) || undefined}>
                        <Typography variant='body2'>{content.label}</Typography>
                        <Typography variant='body2' noWrap={noWrapText} className={classes.cardData}>
                            {renderValue(content, index)}
                        </Typography>
                    </CardContent>
                    <Divider />
                </>
            ))}
        </Card>
    );
};

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
    },

    cardHeader: {
        padding: theme.spacing(1)
    },

    cardData: {
        fontWeight: 700,
    }
}));
