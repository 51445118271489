import {Button, ButtonProps} from '@mui/material'
import {useFormikContext} from 'formik'
import {FC} from 'react'

interface SubmitButtonProps extends ButtonProps {
    label: string
    submittingLabel: string
}

export const SubmitButton: FC<SubmitButtonProps> = ({label, submittingLabel, ...props}) => {
    const {isSubmitting, isValid, submitForm} = useFormikContext()
    return (
        <Button
            type='submit'
            color='primary'
            variant='contained'
            {...props}
            disabled={isSubmitting || props.disabled}
            onClick={e => {
                e.preventDefault()
                submitForm()
            }}>
            {isSubmitting ? submittingLabel : label}
        </Button>
    )
}
