import {Settings as SettingsIcon} from '@mui/icons-material';
import {Button, IconButton, Menu, MenuItem, Theme, useMediaQuery} from '@mui/material';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

import {NotificationBell} from 'components/notifications';
import {useUser} from 'context/user';
import * as settings from 'settings';
import {theme} from 'theme';

import {ApiStatusAlert} from '../taxes/api-status-alert';

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const UserMenu: FC = () => {
    const {classes} = useStyles();
    const router = useRouter();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const {user} = useUser();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfile = async () => {
        handleClose();
        router.push('/users/profile');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        handleClose();
        router.push(settings.LOGOUT_URL);
    };

    if (!user) return null;

    return (
        <div className={classes.root}>
            <ApiStatusAlert />
            {user && user.inAppNotifications && <NotificationBell />}
            {user && smDown && (
                <Link href='/admin' passHref={true}>
                    <IconButton style={{color: theme.palette.background.paper}} size='large'>
                        <SettingsIcon />
                    </IconButton>
                </Link>
            )}
            {user && !smDown && (
                <>
                    <Link href='/admin' target='_blank'>
                        <IconButton style={{color: theme.palette.background.paper}} size='large'>
                            <SettingsIcon />
                        </IconButton>
                    </Link>

                    <Button onClick={handleOpen} style={{color: theme.palette.background.paper}} >
                        {user.displayName}
                    </Button>
                </>
            )}
            {!user && (
                <Link href={settings.LOGIN_URL} passHref={true}>
                    <Button color='primary'>
                        Login
                    </Button>
                </Link>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <li>
                    <MenuItem onClick={handleProfile} component='a'>Perfil</MenuItem>
                </li>
                <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
