import {InputAdornment} from '@mui/material'
import {Formik, useField, useFormikContext} from 'formik'
import React, {FC, useEffect, useMemo} from 'react'

import * as settings from 'settings'

import {NumberInput, NumberInputProps} from './number-input'

type PercentageInputValues = {
    percentage: number
}

const percentageToMultiplier = (value: number | null) => {
    if (!value) return 0
    return Number((value / 100).toFixed(Number(settings.MAX_DECIMALS) + 2))
}

const multiplierToPercentage = (value: number | null) => {
    if (!value) return 0
    return Number((value * 100).toFixed(Number(settings.MAX_DECIMALS)))
}

type SetValueInParentFormProps = {
    setValue(value: number): void
}

const SetValueInParentForm: FC<SetValueInParentFormProps> = ({setValue}) => {
    const {values} = useFormikContext<PercentageInputValues>()
    useEffect(() => {
        setValue(percentageToMultiplier(values.percentage))
    }, [values])
    return null
}

export const PercentageInput: FC<NumberInputProps> = ({name, disabled, ...props}) => {
    const {isSubmitting} = useFormikContext()
    const [field, _meta, helpers] = useField<number | null>(name)

    const initialValues: PercentageInputValues = useMemo(() => {
        return {percentage: multiplierToPercentage(field.value)}
    }, [field.value])

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => {}}>
            <>
                <SetValueInParentForm setValue={helpers.setValue} />
                <NumberInput
                    name='percentage'
                    inputMode='decimal'
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                    disabled={disabled || isSubmitting}
                    {...props}
                />
            </>
        </Formik>
    )
}
