import AddIcon from '@mui/icons-material/Add';
import {
    Autocomplete as MUIAutocomplete,
    CircularProgress,
    createFilterOptions,
    Grid,
    IconButton,
    MenuItem,
    TextField,
} from '@mui/material';
import {Field, FieldProps, Formik, useField, useFormikContext} from 'formik';
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';

import {useUser} from 'context/user';
import {
    Autocomplete,
    AutocompleteMultiple,
    AutocompleteMultiplePublicProps,
    AutocompletePublicProps,
    FormStatus,
    setFilterFieldValue,
} from 'core/components/forms';
import {defaultGridProps} from 'core/components/forms/form';
import {useMobile, useToggle} from 'core/hooks';
import {AccountDialogForm} from 'forms/accounts/account-dialog-form';
import {ContactDialogForm} from 'forms/contacts/contact-dialog-form';
import {SeederDialogForm} from 'forms/seeders/seeder-dialog-form';
import {
    AccountOptionFragment,
    GetChildOrganizationsQueryVariables,
    GetChildUsersQuery,
    OrganizationType,
    PaymentMethodFragment,
    ProductFragment,
    ProductsProductWarrantyClaimReviewStatusChoices,
    QuotesQuoteBillingTypeChoices,
    SupplyOrdersSupplyOrderStatusChoices,
    useGetAccountOptionsQuery,
    useGetChartersQuery,
    useGetChildOrganizationsQuery,
    useGetChildUsersQuery,
    useGetContactAccountLazyQuery,
    useGetContactOptionsLazyQuery,
    useGetContactOptionsQuery,
    useGetContactTypesQuery,
    useGetLocationsQuery,
    useGetOpportunityChannelsQuery,
    useGetOpportunityReasonsQuery,
    useGetOpportunitySituationsQuery,
    useGetPaymentMethodCategoriesQuery,
    useGetPaymentMethodOptionsQuery,
    useGetProductCategoriesQuery,
    useGetProductCategoryLazyQuery,
    useGetProductOptionsQuery,
    useGetQuotePurposesQuery,
    useGetRegionsQuery,
    useGetSeederBrandsQuery,
    useGetSeederModelsLazyQuery,
    useGetSeederOptionsQuery,
    useGetUsersQuery,
    UserType,
    useGetUsersMinimumDataQuery
} from 'generated/graphql';
import {percentageToMultiplier} from 'utils';

export {DeliveryPlacesAutocomplete} from './delivery-places-autocomplete';
export {CloseReasonAutocomplete} from './close-reason-autocomplete';
export {QuoteStatusAutocomplete} from './quote-status-autocomplete';
export {TryAndBuyStatusAutocomplete} from './try-and-buy-status-autocomplete';
export {AnalyticsBillingTypeAutocomplete} from './analytics-billing-type-autocomplete';
export * from './opportunity-status-autocomplete';

export const AccountAutocompleteWithCreate: FC<AutocompletePublicProps> = ({gridProps = defaultGridProps, ...props}) => {
    const {open, toggle} = useToggle();
    const {setFieldValue} = useFormikContext();

    return <>
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <Grid item={true} xs={11}>
                <AccountAutocomplete gridProps={{xs: 12}} {...props} />
            </Grid>
            <Grid item={true} xs={1} alignItems='center' justifyContent='center' container={true}>
                <IconButton onClick={toggle} style={{padding: 0}} size='large'>
                    <AddIcon color='primary' />
                </IconButton>
            </Grid>
        </Grid>
        <AccountDialogForm
            open={open}
            toggle={toggle}
            onSubmit={account => {
                setFieldValue(props.name, account.id, true);
            }}
        />
    </>;
};

export const AccountAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetAccountOptionsQuery();

    return (
        <Autocomplete
            options={data?.accounts.items as Omit<AccountOptionFragment, 'location'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Conta'
            groupBy={option => option.isFavorite ? 'Favorita' : 'Não é favorito'}
            {...props}
        />
    );
};

export const RegionAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetRegionsQuery();
    return (
        <Autocomplete
            options={data?.regions || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Regional'
            {...props}
        />
    );
};

type ContactAutocompleteWithAccountFilterProps = AutocompletePublicProps & {
    initialAccount?: string;
    setAccountState?: (value: string) => void;
};

export const ContactAutocompleteWithAccountFilter: FC<ContactAutocompleteWithAccountFilterProps> = ({initialAccount, setAccountState, ...props}) => {
    const [field, _meta, helpers] = useField<string>(props.name);
    const [selectedAccount, setSelectedAccount] = useState<string>(initialAccount || '');
    const [getContactOptions, {data, loading}] = useGetContactOptionsLazyQuery();
    const [getContactAccount, {data: initialData}] = useGetContactAccountLazyQuery();

    useEffect(() => {
        if (initialAccount && setAccountState) setAccountState(initialAccount);
    }, [initialAccount]);

    const loadOptions = (accountId: string) => {
        // We need an additional variable to check if the loaded options should be displayed or not
        // because we don't have a way to clear the previously loaded options (or do we?).
        setSelectedAccount(accountId);
        if (accountId) getContactOptions({variables: {options: {account: [accountId]}}});
    };

    // Load contacts for initial account selection.
    useEffect(() => {
        if (initialAccount) getContactOptions({variables: {options: {account: [initialAccount]}}});
    }, []);

    // Load parent account for initial value (if any).
    useEffect(() => {
        if (field.value) getContactAccount({variables: {id: field.value}});
    }, []);

    // Load contact options for the parent account of the initial value.
    // This will only happen if initialData changes because of the previous useEffect.
    useEffect(() => {
        if (initialData) loadOptions(initialData.contact.account.id);
    }, [initialData]);

    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={4}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{accountId: initialData?.contact.account.id || selectedAccount}}
                    onSubmit={({accountId}, actions) => {
                        if (accountId) {
                            loadOptions(accountId);
                            if (setAccountState) {
                                setAccountState(accountId);
                            }
                            helpers.setValue('');
                            actions.setSubmitting(false);
                        }
                    }}>
                    <AccountAutocomplete name='accountId' submitOnSelect={true} />
                </Formik>
                <Autocomplete
                    options={selectedAccount && data?.contacts.items || []}
                    loading={loading}
                    valueKey='id'
                    labelKey='displayName'
                    label='Contato'
                    {...props}
                />
            </Grid>
        </Grid>
    );
};

export const ContactTypeAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetContactTypesQuery();
    return (
        <Autocomplete
            options={data?.contactTypes || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Tipo de contato'
            {...props}
        />
    );
};

export const OpportunityChannelAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetOpportunityChannelsQuery();
    return (
        <Autocomplete
            options={data?.opportunityChannels || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Canal'
            {...props}
        />
    );
};

export const OpportunitySellerAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetUsersMinimumDataQuery();
    return (
        <Autocomplete
            options={data?.users as Pick<UserType, 'id' | 'displayName'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='displayName'
            label='Vendedor'
            {...props}
        />
    );
};

export const OpportunityReasonAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetOpportunityReasonsQuery();
    return (
        <Autocomplete
            options={data?.opportunityReasons || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Motivo'
            {...props}
        />
    );
};

export const OpportunitySituationAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetOpportunitySituationsQuery();
    return (
        <Autocomplete
            options={data?.opportunitySituations || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Status'
            {...props}
        />
    );
};

export const BillingTypeAutocomplete: FC<AutocompletePublicProps> = props => {
    const {user} = useUser();

    const [_field, _meta, helpers] = useField<string>('account');
    const {setFieldValue} = useFormikContext();

    const {data} = useGetAccountOptionsQuery();

    return (
        <Grid item={true} {...props.gridProps}>
            <Field name={props.name}>
                {({field: billingField}: FieldProps) => (
                    <TextField
                        fullWidth={true}
                        variant='outlined'
                        select={true}
                        label='Tipo de venda'
                        disabled={props.disabled}
                        {...billingField}
                        InputLabelProps={{
                            required: props.required
                        }}
                        onChange={(event: any) => {
                            setFieldValue(props.name, event.target.value);
                            setFieldValue('paymentMethods', []);
                            if (event.target.value === QuotesQuoteBillingTypeChoices.Indirect) {
                                helpers.setValue(
                                    data?.accounts.items.find(account =>
                                        account.externalId
                                        && user?.organizations.some(org => org.externalId === account.externalId)
                                    )?.id || ''
                                );
                            }
                        }}>
                        <MenuItem value={QuotesQuoteBillingTypeChoices.Direct}>6A - Venda direta ao agricultor</MenuItem>
                        <MenuItem value={QuotesQuoteBillingTypeChoices.Urgency}>6B - Urgência máquina parada</MenuItem>
                        <MenuItem value={QuotesQuoteBillingTypeChoices.Indirect}>6C - Venda indireta (compra própria dealer)</MenuItem>
                    </TextField>
                )}
            </Field>
        </Grid>
    );
};

type ProductCategoryAutocompleteProps = AutocompletePublicProps & {
    productsLoading?: boolean;
};

export const ProductCategoryAutocomplete: FC<ProductCategoryAutocompleteProps> = props => {
    const {data, loading} = useGetProductCategoriesQuery();
    return (
        <Autocomplete
            options={!props.productsLoading && data?.productCategories.map(productCategory => ({id: productCategory.id, name: productCategory.name})) || []}
            loading={loading || props.productsLoading}
            valueKey='id'
            labelKey='name'
            label='Categoria'
            {...props}
        />
    );
};

export const ProductAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetProductOptionsQuery();
    return (
        <Autocomplete
            options={data?.products || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Produto'
            {...props}
        />
    );
};

export const CharterAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetChartersQuery();
    return (
        <Autocomplete
            options={data?.charters || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Frete'
            {...props}
        />
    );
};

type ProductAutocompleteWithCategoryFilterProps = AutocompletePublicProps & {
    products?: ProductFragment[];
};

export const ProductAutocompleteWithCategoryFilter: FC<ProductAutocompleteWithCategoryFilterProps> = props => {
    const [field, _meta, helpers] = useField<string>(props.name);
    const [getProductCategory, {data: productCategoryData, loading: categoriesLoading}] = useGetProductCategoryLazyQuery();
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [filteredProducts, setFilteredProducts] = useState<any>([]);
    const {gridProps, ...rest} = props;

    const loadOptions = (categoryId: string) => {
        // We need an additional variable to check if the loaded options should be displayed or not
        // because we don't have a way to clear the previously loaded options (or do we?).
        if (categoryId && props.products) {
            setSelectedCategory(categoryId);
            setFilteredProducts(props.products.filter(product => product.subcategory.category.id === categoryId));
        }
    };

    // Load category for initial value (if any).
    useEffect(() => {
        if (field.value) getProductCategory({variables: {id: field.value}});
    }, []);

    // Load product options for the parent account of the initial value.
    // This will only happen if category changes because of the previous useEffect.

    useEffect(() => {
        if (productCategoryData) loadOptions(productCategoryData?.product?.subcategory?.category?.id || '');
    }, [productCategoryData]);

    if (categoriesLoading || !props.products?.length) return <Grid item={true} {...gridProps}><CircularProgress /></Grid>;

    return (
        <Grid item={true} {...gridProps}>
            <Grid container={true} spacing={4}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{categoryId: productCategoryData?.product?.subcategory?.category?.id || ''}}
                    onSubmit={({categoryId}, actions) => {
                        if (categoryId) {
                            loadOptions(categoryId);
                            helpers.setValue('');
                            actions.setSubmitting(false);
                        } else {
                            helpers.setValue('');
                            actions.setSubmitting(false);
                        }
                    }}>
                    <ProductCategoryAutocomplete
                        name='categoryId'
                        submitOnSelect={true}
                        productsLoading={!props.products.length}
                        size={props.size}
                    />
                </Formik>
                <Autocomplete
                    options={selectedCategory ? filteredProducts : props.products || []}
                    loading={!props.products.length}
                    valueKey='id'
                    labelKey='displayName'
                    label='Produto'
                    {...rest}
                />
            </Grid>
        </Grid>
    );
};

type ChildOrganizationAutocompleteProps = AutocompletePublicProps & {queryVariables?: GetChildOrganizationsQueryVariables;};

export const ChildOrganizationAutocomplete: FC<ChildOrganizationAutocompleteProps> = ({queryVariables = {onlyPublished: false}, ...props}) => {
    const {data, loading} = useGetChildOrganizationsQuery({variables: queryVariables});
    return (
        <Autocomplete
            options={data?.childOrganizations as Pick<OrganizationType, 'id' | 'name'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Organização atribuída'
            {...props}
        />
    );
};

type ChildOrganizationAutocompleteMultipleProps = AutocompleteMultiplePublicProps & {queryVariables?: GetChildOrganizationsQueryVariables;};

export const ChildOrganizationAutocompleteMultiple: FC<ChildOrganizationAutocompleteMultipleProps> = ({queryVariables = {onlyPublished: false}, ...props}) => {
    const {data, loading} = useGetChildOrganizationsQuery({variables: queryVariables});

    return (
        <AutocompleteMultiple
            options={data?.childOrganizations || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Organizações'
            {...props}
        />
    );
};

export const SeederBrandAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetSeederBrandsQuery();
    return (
        <Autocomplete
            options={data?.seederBrands || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Marca'
            {...props}
        />
    );
};

export const LocationAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetLocationsQuery({variables: {level: 2}});
    return (
        <Autocomplete
            options={data?.locations || []}
            loading={loading}
            valueKey='id'
            labelKey='path'
            label='Localização'
            {...props}
        />
    );
};

export const UserAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetUsersQuery();
    return (
        <Autocomplete
            options={data?.users as Pick<UserType, 'id' | 'displayName'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='displayName'
            label='Usuário'
            {...props}
        />
    );
};

export const ChildUserAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetChildUsersQuery();

    return (
        <Autocomplete
            options={data?.childUsers as Pick<UserType, 'id' | 'displayName'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='displayName'
            {...props}
        />
    );
};

type SeederAutocompleteProps = AutocompletePublicProps & {
    accountFieldName: string;
    rowCountFieldName?: string;
};

export const SeederAutocomplete: FC<SeederAutocompleteProps> = ({accountFieldName, rowCountFieldName, ...props}) => {
    const [field, , helpers] = useField<string>(props.name);
    const [accountField] = useField<string>(accountFieldName);
    const {setFieldValue, initialValues = {} as any} = useFormikContext();
    const {data, loading} = useGetSeederOptionsQuery({
        skip: !accountField.value,
        variables: {accountId: accountField.value},
    });

    useEffect(() => {
        if (!accountField.value) helpers.setValue('');
    }, [accountField.value]);
    useEffect(() => {
        if (!rowCountFieldName) return;
        const seeder = field.value && data?.seeders.find(({id}) => id === field.value);
        setFieldValue(rowCountFieldName, seeder ? seeder.rowCount : initialValues[rowCountFieldName], false);
    }, [data, field.value]);

    return (
        <Autocomplete
            options={data?.seeders.map(({rowCount, ...rest}) => ({...rest})) || []}
            disabled={!accountField.value}
            loading={loading}
            valueKey='id'
            labelKey='displayName'
            label='Plantadeira'
            {...props}
        />
    );
};

export const SeederAutocompleteWithCreate: FC<SeederAutocompleteProps> = ({gridProps = defaultGridProps, accountFieldName, ...props}) => {
    const [accountField] = useField<string>(accountFieldName);
    const {open, toggle} = useToggle();

    return (
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <Grid item={true} xs={11}>
                <SeederAutocomplete accountFieldName={accountFieldName} gridProps={{xs: 12}} {...props} />
            </Grid>
            <Grid item={true} xs={1} alignItems='center' justifyContent='center' container={true}>
                <IconButton
                    onClick={toggle}
                    disabled={!accountField.value}
                    style={{padding: 0}}
                    size='large'>
                    <AddIcon color='primary' />
                </IconButton>
            </Grid>
            <SeederDialogForm open={open} seederFieldName={props.name} accountId={accountField.value} toggle={toggle} />
        </Grid>
    );
};

type SeederModelAutocompleteProps = AutocompletePublicProps & {
    seederBrandFieldName: string;
};

export const SeederModelAutocomplete: FC<SeederModelAutocompleteProps> = ({seederBrandFieldName, ...props}) => {
    const [getSeederModels, {data, loading}] = useGetSeederModelsLazyQuery();
    const [seederBrandField] = useField<string>(seederBrandFieldName);

    useEffect(() => {
        if (seederBrandField.value) getSeederModels({variables: {seederBrandId: seederBrandField.value}});
    }, [seederBrandField.value]);

    return (
        <Autocomplete
            options={seederBrandField.value && data?.seederModels || []}
            loading={loading}
            disabled={!seederBrandField.value}
            valueKey='id'
            labelKey='name'
            label='Modelo'
            {...props}
        />
    );
};

type ContactAutocompleteProps = AutocompletePublicProps & {
    accountFieldName: string;
};

export const ContactAutocomplete: FC<ContactAutocompleteProps> = ({accountFieldName, ...props}) => {
    const [accountField] = useField<string>(accountFieldName);
    const [, , helpers] = useField(props.name);
    const {data, loading} = useGetContactOptionsQuery({
        skip: !accountField.value,
        variables: {options: {account: [accountField.value]}},
    });

    useEffect(() => {
        if (!accountField.value) helpers.setValue('');
    }, [accountField.value]);

    return (
        <Autocomplete
            options={accountField.value && data?.contacts.items || []}
            loading={loading}
            disabled={!accountField.value}
            valueKey='id'
            labelKey='displayName'
            label='Contato'
            {...props}
        />
    );
};

export const ContactAutocompleteWithCreate: FC<ContactAutocompleteProps> = ({gridProps = defaultGridProps, accountFieldName, ...props}) => {
    const [accountField] = useField<string>(accountFieldName);
    const {open, toggle} = useToggle();

    return <>
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <Grid item={true} xs={11} container={true}>
                <ContactAutocomplete accountFieldName={accountFieldName} gridProps={{xs: 12}} {...props} />
            </Grid>
            <Grid item={true} xs={1} alignItems='center' justifyContent='center' container={true}>
                <IconButton
                    onClick={toggle}
                    disabled={!accountField.value}
                    style={{padding: 0}}
                    size='large'>
                    <AddIcon color='primary' />
                </IconButton>
            </Grid>
        </Grid>
        <ContactDialogForm
            open={open}
            toggle={toggle}
            accountId={accountField.value}
            contactFieldName={props.name}
        />
    </>;
};

export const ChildUserGroupByRoleAutocomplete: FC<AutocompletePublicProps<GetChildUsersQuery['childUsers'][number]>> = ({filterOptions, ...props}) => {
    const isMobile = useMobile();
    const {data, loading} = useGetChildUsersQuery();
    const {setFieldValue, submitForm, status} = useFormikContext();
    const [field] = useField<string>(props.name);
    const options = useMemo(() => [...(data?.childUsers || [])]
        .sort((a, b) => (a.role?.name || '').localeCompare(b.role?.name || '')) as any
        , [data]);
    const filteredOptions = useMemo(() => filterOptions && options.filter(filterOptions) || options, [options, filterOptions]);
    const value = data?.childUsers.find(option => option.id === field.value) || null;

    useEffect(() => {
        if (status === FormStatus.filters) {
            setFilterFieldValue(props.name, {
                type: 'single',
                value: {
                    value: value?.id,
                    label: `Conta atribuída a: ${value?.displayName}`,
                    name: props.name,
                    handleDelete: () => {
                        setFieldValue(props.name, '', true);
                        submitForm();
                    }
                }
            }, setFieldValue);
        }
    }, [field.value, props.label, status]);

    return (
        <MUIAutocomplete
            options={filteredOptions}
            groupBy={option => option.role?.name || ''}
            getOptionLabel={option => option.displayName || ''}
            filterOptions={createFilterOptions({stringify: option => option.displayName})}
            onChange={(event, value) => {
                setFieldValue(props.name, value ? value.id : '', true);
                if (props.submitOnSelect) submitForm();
            }}
            isOptionEqualToValue={(option, selected) => option.id === selected.id}
            loading={loading}
            value={value}
            renderInput={params => <TextField {...params} label='Conta atribuída a' variant='outlined' InputLabelProps={{shrink: isMobile ? true : undefined}} />}
            {...props}
        />
    );
};

type QuotePurposeAutocompleteProps = AutocompletePublicProps & {
    setValue(field: string, value: string, shouldValidate?: boolean): void;
};

export const QuotePurposeAutocomplete: FC<QuotePurposeAutocompleteProps> = ({setValue, ...props}) => {
    const {data, loading} = useGetQuotePurposesQuery();

    useEffect(() => {
        // if there is only one option, the value must be set and and the field should not be displayed
        if (data?.quotePurposes?.length === 1) {
            setValue(props.name, data.quotePurposes[0].id);
        }
    }, [data?.quotePurposes]);

    if (!data || data?.quotePurposes?.length < 2) return null;

    return (
        <Autocomplete
            options={data?.quotePurposes || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Motivo'
            {...props}
        />
    );
};

export const WarrantyClaimStatusAutocomplete: FC<AutocompletePublicProps> = props => {
    const options = [
        {value: ProductsProductWarrantyClaimReviewStatusChoices.Approved, label: 'Aprovado'},
        {value: ProductsProductWarrantyClaimReviewStatusChoices.Cancelled, label: 'Cancelada'},
        {value: ProductsProductWarrantyClaimReviewStatusChoices.Pending, label: 'Pendente'},
        {value: ProductsProductWarrantyClaimReviewStatusChoices.RequestMoreInfo, label: 'Mais informações solicitadas'},
        {value: ProductsProductWarrantyClaimReviewStatusChoices.Rejected, label: 'Rejeitado'},
        {value: ProductsProductWarrantyClaimReviewStatusChoices.Sent, label: 'Enviado'},
        {value: ProductsProductWarrantyClaimReviewStatusChoices.Finished, label: 'Envio finalizado'},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='value'
            label='Status'
            {...props}
        />
    );
};

export const PaymentMethodCategoryAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetPaymentMethodCategoriesQuery();
    return (
        <Autocomplete
            options={data?.paymentMethodCategories || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Categoria de forma de pagamento'
            gridProps={{xs: 12}}
            {...props}
        />
    );
};

type PaymentMethodAutocompleteWithCategoryFilterProps = AutocompletePublicProps & {
    initialCategory?: string;
    customPaymentMethodOption?: {id: string; name: string;}[];
};

type PaymentMethodAutocompleteProps = AutocompletePublicProps & {
    billingType: string;
    customPaymentMethodOption?: {id: string; name: string;}[];
};

export const PaymentMethodAutocomplete: FC<PaymentMethodAutocompleteProps> = ({
    billingType, customPaymentMethodOption, ...props
}) => {
    const {data, loading} = useGetPaymentMethodOptionsQuery({variables: {billingType}});

    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                    <Autocomplete
                        options={customPaymentMethodOption && data?.paymentMethods.concat(customPaymentMethodOption) || []}
                        loading={loading}
                        valueKey='id'
                        labelKey='name'
                        label='Forma de pagamento'
                        gridProps={{xs: 8}}
                        {...props}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export const SupplyOrdersStatusAutocomplete: FC<AutocompletePublicProps> = props => {
    const options = [
        {value: SupplyOrdersSupplyOrderStatusChoices.Draft, name: 'Rascunho'},
        {value: SupplyOrdersSupplyOrderStatusChoices.SalesReview, name: 'Revisão de vendas'},
        {value: SupplyOrdersSupplyOrderStatusChoices.CreditsReview, name: 'Pendente de aprovação'},
        {value: SupplyOrdersSupplyOrderStatusChoices.Approved, name: 'Aprovada'},
        {value: SupplyOrdersSupplyOrderStatusChoices.InProgress, name: 'Em operação'},
        {value: SupplyOrdersSupplyOrderStatusChoices.Finished, name: 'Envio finalizado'},
        {value: SupplyOrdersSupplyOrderStatusChoices.Cancelled, name: 'Cancelada'},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='name'
            label='Status'
            {...props}
        />
    );
};

type PaymentMethodDiscountAutocompleteProps = AutocompletePublicProps & {
    maxDiscount: number;
};

export const PaymentMethodDiscountAutocomplete: FC<PaymentMethodDiscountAutocompleteProps> = props => {
    const [field, _meta, helpers] = useField<string>(props.name);
    const options = Array.from(Array(Math.ceil(props.maxDiscount / 0.5) + 1).keys()).map(i => i * 0.5);

    useEffect(() => {
        if (field.value === '') helpers.setValue(percentageToMultiplier(options[0], '-').toString());
    }, [field.value]);

    return (
        <Autocomplete
            defaultValue={{value: percentageToMultiplier(options[0], '-').toString(), label: options[0] + ' %'}}
            options={options.map(n => ({value: percentageToMultiplier(n, '-').toFixed(4), label: n + ' %'}))}
            valueKey='value'
            labelKey='label'
            disableClearable={true}
            {...props}
        />
    );
};

export const PaymentMethodFixedDiscountAutocomplete: FC<{
    rules: PaymentMethodFragment['billingTypeRules'][number]['rules'];
} & AutocompletePublicProps> = ({rules, ...props}) => {
    const options = rules.map(({id, discount}) => ({value: id, label: '' + discount + ' %'}));
    return (
        <Autocomplete
            defaultValue={options[0]}
            options={options}
            valueKey='value'
            labelKey='label'
            disableClearable={true}
            {...props}
        />
    );
};
