import {SvgIcon, SvgIconProps} from '@mui/material'
import {FC} from 'react'

export const RopIcon: FC<SvgIconProps> = ({...props}) => {
    return (
        <SvgIcon {...props} viewBox='0 0 23 25' width={23} height={25}>
            <g clip-path='url(#clip0_1628_408)'>
                <path d='M16.2307 7.50582H13.4436C13.4579 7.57669 13.4651 7.64878 13.4652 7.72105V10.9688C13.4652 11.2575 13.3486 11.5344 13.1412 11.7385C12.9337 11.9427 12.6524 12.0574 12.359 12.0574H10.5051C10.2117 12.0574 9.93028 11.9427 9.72272 11.7386C9.51517 11.5344 9.39849 11.2575 9.39832 10.9688V7.72105C9.39826 7.64875 9.40571 7.57663 9.42057 7.50582H6.63473C6.09518 7.50582 5.57772 7.71676 5.1962 8.09221C4.81468 8.46767 4.60034 8.9769 4.60034 9.50787V18.1373C4.60034 18.6683 4.81468 19.1776 5.1962 19.553C5.57772 19.9285 6.09518 20.1394 6.63473 20.1394H16.2307C16.7702 20.1394 17.2877 19.9285 17.6692 19.553C18.0507 19.1776 18.2651 18.6683 18.2651 18.1373V9.506C18.2646 8.97535 18.05 8.4666 17.6685 8.09155C17.2871 7.7165 16.7699 7.50582 16.2307 7.50582ZM9.15673 18.0416H6.7409V16.617H9.15673V18.0416ZM11.0996 15.2081H6.7765V13.7835H11.0996V15.2081Z' />
                <path d='M18.3805 2.85856H3.12262L4.25615 1.34889C4.29378 1.29864 4.32085 1.24152 4.33579 1.18087C4.35074 1.12021 4.35325 1.05724 4.34319 0.995613C4.33313 0.93399 4.31069 0.87496 4.27719 0.821965C4.24369 0.76897 4.1998 0.723071 4.14808 0.686954L3.28664 0.0863399C3.18476 0.0154314 3.05871 -0.0134033 2.93549 0.00600779C2.81227 0.0254189 2.70167 0.0915351 2.62737 0.190199L0.0913853 3.56866C0.0327738 3.64672 0.000199347 3.74074 -0.00185011 3.83778C-0.00389957 3.93481 0.0246753 4.03008 0.0799404 4.11047L2.50213 7.63533C2.53727 7.68663 2.58238 7.73058 2.63485 7.76466C2.68731 7.79874 2.74611 7.82227 2.80786 7.8339C2.8696 7.84553 2.93308 7.84503 2.99462 7.83243C3.05617 7.81982 3.11458 7.79536 3.16649 7.76046L4.01775 7.18674C4.11909 7.11844 4.18974 7.01428 4.21497 6.89598C4.24019 6.77768 4.21805 6.65435 4.15316 6.55172L3.0457 4.79992H18.3805C19.0827 4.80091 19.7559 5.07592 20.2524 5.56464C20.7489 6.05336 21.0282 6.7159 21.029 7.40697V20.456C21.0282 21.147 20.7489 21.8095 20.2524 22.2981C19.7559 22.7867 19.0827 23.0616 18.3805 23.0624H4.65604C3.95381 23.0616 3.28057 22.7867 2.78396 22.2981C2.28735 21.8095 2.00789 21.147 2.00689 20.456V14.4986C2.00689 14.3742 1.95665 14.2548 1.86723 14.1668C1.77781 14.0788 1.65653 14.0294 1.53008 14.0294H0.512883C0.386425 14.0294 0.265148 14.0788 0.175729 14.1668C0.08631 14.2548 0.0360738 14.3742 0.0360738 14.4986V20.456C0.037925 21.661 0.525347 22.8161 1.39143 23.668C2.25751 24.5198 3.43155 24.9988 4.65604 25H18.3805C19.6052 24.9987 20.7794 24.5193 21.6454 23.6671C22.5114 22.8148 22.9985 21.6593 22.9998 20.4541V7.40509C22.9985 6.19979 22.5114 5.04423 21.6454 4.19189C20.7794 3.33955 19.6053 2.86005 18.3805 2.85856Z' />
            </g>
            <defs>
                <clipPath id='clip0_1628_408'>
                    <rect width='23' height='25' fill='white' />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
