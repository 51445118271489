import {FormikHelpers} from 'formik';

import {TextInput, TextInputProps} from './text-input';

export {Autocomplete} from './autocomplete';
export type {AutocompletePublicProps} from './autocomplete';
export {AutocompleteMultiple} from './autocomplete-multiple';
export type {AutocompleteMultiplePublicProps} from './autocomplete-multiple';
export {DatePicker} from './date-picker';
export {Form} from './form';
export {FormActions} from './form-actions';
export {FormErrors} from './form-errors';
export {MultiplierInput} from './multiplier-input';
export {NumberInput} from './number-input';
export {PercentageInput} from './percentage-input';
export {SubmitButton} from './submit-button';
export {TextInput} from './text-input';
export {Checkbox} from './checkbox';

// Alias old Input to new TextInput.
// TODO: Delete this file in future versions.
export const Input = TextInput;
export type InputProps = TextInputProps;

// TODO: Move this helper into each project because it's not generic enough to be in core.
// Had to redefine ErrorType here to not depend on graphql generated code.
type ErrorType = {
    field: string;
    messages: string[];
};

export enum FormStatus {
    filters = 'FILTERS'
}

export type FilterState<T = any> = {value: T, label: string, name: string, handleDelete?: () => void;};

export type FilterStates<T = any> =
    | {type: 'single', value: FilterState<T>;}
    | {type: 'array', value: FilterState<T>[];};

export const setFilterFieldValue = <T,>(
    name: string,
    state: FilterStates<T>,
    setFieldValue: FormikHelpers<any>['setFieldValue']
) => {
    setFieldValue(`_state.${name}`, state);
};

export type WithFiltersState<T extends object> = {
    _state?: {[key in keyof T]: FilterStates};
} & T;

export const setErrors = (errors: ErrorType[] | undefined, actions: FormikHelpers<any>) => {
    errors?.forEach(error => {
        const messages = error.messages.join('\n');
        if (error.field.toLowerCase().includes('_all_')) {
            actions.setStatus({formErrors: messages});
        } else {
            actions.setFieldError(error.field, messages);
        }
    });
};

export const formatErrorsToString = (errors: ErrorType[] | undefined) => {
    return errors?.reduce<string>((acc, curr) => {
        const messages = curr.messages.join('\n');
        return [acc, messages].join('\n');
    }, '');
};