import {FC} from 'react';

import {Autocomplete, AutocompletePublicProps} from 'core/components/forms';
import {QuotesQuoteBillingTypeChoices} from 'generated/graphql';

export const AnalyticsBillingTypeAutocomplete: FC<AutocompletePublicProps> = props => {
    const options = [
        {id: QuotesQuoteBillingTypeChoices.Direct, name: '6A - Venda direta ao agricultor'},
        {id: QuotesQuoteBillingTypeChoices.Urgency, name: '6B - Urgência máquina parada'},
        {id: QuotesQuoteBillingTypeChoices.Indirect, name: '6C - Venda indireta (compra própria dealer)'},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='id'
            labelKey='name'
            label='Tipo de venda'
            {...props}
        />
    );
};
