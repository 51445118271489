import {PureQueryOptions} from '@apollo/client'
import {Button, Grid, TableContainer, Theme} from '@mui/material'
import {Formik, FormikHelpers} from 'formik'
import {useSnackbar} from 'notistack'
import React, {FC, useMemo} from 'react'
import {makeStyles} from 'tss-react/mui'

import {AsyncMobileCard} from 'components/async-card-mobile'
import {AsyncTable} from 'components/async-table'
import {CloseReasonAutocomplete} from 'components/autocompletes'
import {Form, FormActions, FormErrors, SubmitButton} from 'core/components/forms'
import {useMobile} from 'core/hooks'
import {formatDate} from 'core/locale'
import {
    ExpiredQuoteFragment,
    GetExpiredQuotesDocument,
    QuoteCloseReasonInputType,
    useCloseQuotesMutation,
} from 'generated/graphql'

interface ExpiredQuotesFormProps {
    quotes: ExpiredQuoteFragment[]
    onSubmit: () => void
    onCancel: () => void
    refetchQueries?: PureQueryOptions[]
}

interface CloseQuoteFormValues {closeReasons: QuoteCloseReasonInputType[]}

export const ExpiredQuotesForm: FC<ExpiredQuotesFormProps> = ({quotes, onSubmit, onCancel, refetchQueries = []}) => {
    const isMobile = useMobile()
    const {classes} = useStyles()
    const [closeQuotes] = useCloseQuotesMutation()
    const {enqueueSnackbar} = useSnackbar()

    const initialValues: CloseQuoteFormValues = useMemo(() => {
        return {
            closeReasons: quotes.map(quote => ({
                quote: quote.id,
                description: '',
            }))
        }
    }, [quotes])

    const handleSubmit = async (values: CloseQuoteFormValues, {setSubmitting, setStatus}: FormikHelpers<CloseQuoteFormValues>) => {
        setSubmitting(true)
        if (values.closeReasons.every(reason => !reason.description)) {
            setStatus({formErrors: 'Você deve fechar pelo menos uma linha'})
            return setSubmitting(false)
        }
        const {data} = await closeQuotes({
            variables: {input: values.closeReasons.filter(reason => Boolean(reason.description))},
            refetchQueries: [
                {query: GetExpiredQuotesDocument},
                ...refetchQueries,
            ],
        })
        if (data?.closeQuotes?.status) {
            enqueueSnackbar('Cotações fechadas!', {variant: 'success'})
        }
        setSubmitting(false)
        onSubmit()
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}>
            <Form withBackdrop={true}>
                <Grid item={true} xs={12}>
                    <TableContainer style={{display: 'block', maxHeight: '400px'}}>
                        {isMobile ? (
                            <AsyncMobileCard
                                querystringKey='quotes-expired'
                                data={quotes}
                                count={quotes.length}
                                disableToolbar={true}
                                fetchData={() => {}}
                                initialPageSize={100}
                                headers={[
                                    {key: 'displayId', render: row => <>ID: {row.displayId}</>, color: 'primary'},
                                    {key: 'created', render: row => formatDate(row.created), color: 'secondary'},
                                ]}
                                contents={[
                                    {key: 'contact.account.name', label: 'Conta'},
                                    {key: 'contact.email', label: 'Contato'},
                                    {key: 'createdBy.displayName', label: 'Criado por'},
                                    {
                                        key: 'closeReason', label: 'Razão do não fechamento', render: (row, indexRow, indexCol) => {
                                            return <div className={classes.autocomplete}><CloseReasonAutocomplete name={`closeReasons.${indexRow}.description`} gridProps={{xs: 12}} /></div>
                                        }
                                    },
                                ]}
                            />
                        ) : (
                            <AsyncTable
                                querystringKey='quotes-expired'
                                data={quotes}
                                count={quotes.length}
                                disableToolbar={true}
                                fetchData={() => {}}
                                initialPageSize={100}
                                columns={[
                                    {key: 'displayId', label: 'ID'},
                                    {key: 'created', label: 'Data', render: row => formatDate(row.created)},
                                    {key: 'contact.account.name', label: 'Conta'},
                                    {key: 'contact.email', label: 'Contato'},
                                    {key: 'createdBy.displayName', label: 'Criado por'},
                                    {
                                        key: 'closeReason', label: 'Razão do não fechamento', render: (row, indexRow, indexCol) => {
                                            return <div className={classes.autocomplete}><CloseReasonAutocomplete name={`closeReasons.${indexRow}.description`} gridProps={{xs: 12}} /></div>
                                        }
                                    },
                                ]}
                            />
                        )}
                    </TableContainer>
                </Grid>
                <FormErrors />
                <Grid item={true} xs={12}>
                    <FormActions>
                        <Grid container={true} justifyContent='flex-end' spacing={2}>
                            <Grid item={true}>
                                <Button onClick={onCancel}>Cancelar</Button>
                            </Grid>
                            <Grid item={true}>
                                <SubmitButton label='Salvar' submittingLabel='Salvando...' />
                            </Grid>
                        </Grid>
                    </FormActions>
                </Grid>
            </Form>
        </Formik>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    autocomplete: {
        minWidth: '250px',
    }
}))
