import {Grid} from '@mui/material'
import {useField} from 'formik'
import {FC, useEffect} from 'react'

import {Autocomplete, AutocompletePublicProps, TextInput} from 'core/components/forms'
import {
    ProductsProductWarrantyClaimDeliveryPlaceChoices,
    useGetAccountOptionLazyQuery,
    useGetLocationLazyQuery,
    useGetOrganizationsQuery,
} from 'generated/graphql'

import {ChildOrganizationAutocomplete} from '.'

type Props = {
    locationName: string;
    organizationName: string;
    accountName: string;
} & Omit<AutocompletePublicProps, 'gridProps'>;

export const DeliveryPlacesAutocomplete: FC<Props> = ({locationName, accountName, organizationName, name, ...props}) => {
    const [delivery] = useField<string>(name);
    const [organization, ___, organizationHelpers] = useField<string>(organizationName);
    const [account] = useField<string>(accountName);
    const [_, __, locationHelpers] = useField<string>(locationName);
    const [getLocation, {data: locationData}] = useGetLocationLazyQuery();
    const {data: organizations} = useGetOrganizationsQuery();
    const [getAccount, {data: accountData, loading: loadingAccount}] = useGetAccountOptionLazyQuery();

    const isShop = delivery.value === ProductsProductWarrantyClaimDeliveryPlaceChoices.Shop.toLowerCase();
    const options = [
        {id: ProductsProductWarrantyClaimDeliveryPlaceChoices.Client.toLowerCase(), name: 'Cliente'},
        {id: ProductsProductWarrantyClaimDeliveryPlaceChoices.Shop.toLowerCase(), name: 'Loja'},
    ];

    useEffect(() => {
        if (account.value) getAccount({variables: {id: account.value}});
    }, [account.value]);

    useEffect(() => {
        if (!isShop) organizationHelpers.setValue('');
    }, [delivery.value]);

    useEffect(() => {
        let path = '';
        if (isShop) {
            if (organization.value) {
                const organizationSelected = organizations?.organizations.find(({id}) => id === organization.value);
                path = organizationSelected?.location?.path || '';
            }
        } else if (accountData?.account?.location?.id) {
            getLocation({variables: {id: accountData?.account?.location?.id}});
        }
        locationHelpers.setValue(path);
    }, [delivery.value, accountData, organization.value, loadingAccount]);

    useEffect(() => {
        if (locationData?.location?.path) locationHelpers.setValue(locationData?.location?.path);
    }, [locationData]);

    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
                <Autocomplete
                    options={options}
                    name={name}
                    valueKey='id'
                    labelKey='name'
                    label='Onde entregar a nova peça?'
                    gridProps={{xs: isShop ? 3 : 6}}
                    {...props}
                />
                {isShop
                    && <ChildOrganizationAutocomplete name={organizationName} gridProps={{xs: 3}} />}
                <TextInput name={locationName} label='Localização' disabled={true} gridProps={{xs: 6}} />
            </Grid>
        </Grid>
    );
};
