import {Pagination, PaginationItem} from '@mui/lab'
import {Theme} from '@mui/material'
import {TablePaginationActionsProps} from '@mui/material/TablePagination/TablePaginationActions'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

export const AsyncTablePagination: FC<TablePaginationActionsProps> = ({
    onPageChange,
    count,
    rowsPerPage,
    page,
}) => {
    const {
        classes: {
            root,
            selected,
        }
    } = useStyles()

    return (
        <Pagination
            count={Math.ceil(count / rowsPerPage)}
            page={page + 1}
            onChange={(e: any, page: number) => onPageChange(e, page - 1)}
            siblingCount={1}
            boundaryCount={1}
            renderItem={item => <PaginationItem classes={{root, selected}} {...item} />}
        />
    )
}

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        fontWeight: theme.typography.fontWeightBold,
        color: `${theme.palette.primary.main} !important`,
    },

    selected: {
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: theme.typography.fontWeightBold,
    }
}))
