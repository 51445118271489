import {
    Block as EmptyIcon,
    Clear as ClearIcon,
    Clear as ClearSearchIcon,
    Search as SearchIcon,
    Tune as TuneIcon,
} from '@mui/icons-material'
import {
    Button,
    Chip,
    CircularProgress,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    InputBase,
    Paper,
    Typography,
    alpha,
} from '@mui/material'
import Pagination from '@mui/material/Pagination'
import {Theme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {Form, Formik, FormikHelpers, FormikProps} from 'formik'
import {useRouter} from 'next/router'
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {FilterState, FilterStates, FormStatus, WithFiltersState} from 'core/components/forms'
import {useDebounce, useLocalStorage} from 'core/hooks'
import {decodeFromB64, encodeToB64} from 'utils'

import {AsyncCard, AsyncCardProps} from './async-card'
import {theme as defaultTheme} from './theme'

export const useStyles = makeStyles()((theme: Theme) => ({
    search: {
        alignSelf: 'center',
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        backgroundColor: theme.palette.grey[300],
        borderRadius: '10px',
        '& > * + *': {
            marginLeft: theme.spacing(2),
            flexGrow: 1,
        },
    },

    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4),
    },

    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(4),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },

    filterButton: {
        backgroundColor: '#3F4443',
        color: '#FFFFFF',
        borderRadius: '10px',
        '&:hover,&:focus': {
            backgroundColor: alpha('#3F4443', .5),
        },
    },

    summary: {
        '&.Mui-focused': {
            backgroundColor: 'unset',
        },
    },

    expansionPanel: {
        border: 'unset',
        backgroundColor: 'unset',
    },

    paginationSpacer: {
        '& ul': {
            justifyContent: 'center',
        },
    },

    chipRoot: {
        height: '100%',
        display: 'flex',
    },

    chipLabel: {
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        textOverflow: 'clip',
    }
}))

export type TableOptions = {
    page: number
    pageSize: number
    ordering: string
    query: string
}

export type AsyncTableQuerystringProps = {
    page: string
    expanded: string
    pageSize: string
    filters: string
    q: string
    order: string
}

export type AsyncTableProps<T extends object> = {
    querystringKey: string,
    data: T[]
    count: number
    loading: boolean
    initialPageSize: number
    theme?: Theme
    initialPage?: number
    pageIndexBase?: 0 | 1
    initialFilters?: T
    initialOrdering?: string
    initialQuery?: string
    filterComponents?: ReactNode[]
    fetchData(options: TableOptions): any
    disableToolbar?: boolean
    emptyNode?: React.ReactNode
} & AsyncCardProps<T>

export function AsyncMobileCard<T extends {id: string}>({
    querystringKey,
    data,
    count,
    loading,
    headers,
    contents,
    initialPageSize,
    theme = defaultTheme,
    initialPage = 0,
    pageIndexBase = 0,
    initialFilters = {} as T,
    initialQuery = '',
    initialOrdering,
    filterComponents,
    disableToolbar,
    fetchData,
    getRowLink,
    emptyNode,
    moreOptions,
}: AsyncTableProps<T>) {
    const router = useRouter()
    const {classes} = useStyles()
    const searchInputRef = useRef<HTMLInputElement>()
    const [querystring, setQuerystring] = useLocalStorage<AsyncTableQuerystringProps>(`${querystringKey}-querystring`, {} as AsyncTableQuerystringProps)
    const [expandedFilters, setExpandedFilters] = useState(querystring.expanded === 'true' || false)
    const initialFiltersValues = querystring.filters ? decodeFromB64(querystring.filters) : initialFilters
    const [filtersValues, setFiltersValues] = useState<T>(initialFiltersValues)

    const pageSize = initialPageSize || 20
    const [page, setPage] = useState(Number(querystring.page) || initialPage)
    const [ordering, setOrdering] = useState(querystring.order || initialOrdering || headers && `+${headers[0].key}` || `+${contents[0].key}`)
    const [query, setQuery] = useState(querystring.q || initialQuery)

    const debouncedPage = useDebounce(page, 250)
    const debouncedQuery = useDebounce(query, 250)

    useEffect(() => {
        const prevPath = localStorage.getItem('prevPath')
        if (prevPath && (!prevPath.includes(router.asPath) || prevPath.includes('new'))) {
            setQuerystring({} as AsyncTableQuerystringProps)
            setPage(initialPage)
            setQuery(initialQuery)
            setOrdering(initialOrdering || headers && `+${headers[0].key}` || `+${contents[0].key}`)
            setFiltersValues(initialFilters)
            setExpandedFilters(false)
        }
    }, [router.asPath])
    const handleToggleFilters = useCallback(
        () => {
            setQuerystring({...querystring, expanded: (!expandedFilters).toString()})
            setExpandedFilters(current => !current)
        },
        [setExpandedFilters],
    )

    const onSubmitFilters = ({_state, ...values}: WithFiltersState<any>, helpers: FormikHelpers<T>) => {
        //Delete empty values
        setFiltersValues(Object.keys(values).reduce((acc, key) => (values[key] === '' ? acc : {...acc, [key]: values[key]}), {}) as T)
        setPage(initialPage)
        helpers.setSubmitting(false)
        handleToggleFilters()
    }

    const handleResetFilters = (formikProps: FormikProps<T>) => {
        formikProps.resetForm()
        setFiltersValues(({} as T))
        setQuerystring({
            ...querystring,
            pageSize: pageSize.toString(),
            page: initialPage.toString(),
            filters: encodeToB64({}),
        })
    }

    useEffect(() => {
        setQuerystring({
            ...querystring,
            page: debouncedPage,
            q: debouncedQuery,
            order: ordering,
            filters: encodeToB64(filtersValues),
        })
        fetchData({
            pageSize,
            ordering,
            page: (debouncedPage + pageIndexBase),
            query: debouncedQuery,
            ...filtersValues
        } as any)
    }, [debouncedPage, ordering, debouncedQuery, filtersValues, pageSize])

    const toolbar = useMemo(() => {
        return (
            <Formik<WithFiltersState<typeof filtersValues>>
                initialValues={{...initialFiltersValues, _state: undefined}}
                enableReinitialize={true}
                onSubmit={onSubmitFilters}
                initialStatus={FormStatus.filters}>
                {formikProps => {
                    return (
                        <Form>
                            <Accordion expanded={expandedFilters} className={classes.expansionPanel} elevation={0}>
                                <AccordionSummary className={classes.summary}>
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={10} >
                                            <InputBase
                                                className={classes.search}
                                                startAdornment={
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => {
                                                            if (query) setQuery('')
                                                            else searchInputRef.current?.focus()
                                                        }}>
                                                        {query ? <ClearSearchIcon /> : <SearchIcon />}
                                                    </IconButton>}
                                                placeholder='Pesquisar'
                                                inputRef={searchInputRef}
                                                value={query}
                                                onChange={event => {
                                                    setQuery(event.target.value)
                                                    setPage(initialPage)
                                                }}
                                            />
                                        </Grid>
                                        {filterComponents && (
                                            <Grid item={true} xs={2}>
                                                <IconButton
                                                    className={classes.filterButton}
                                                    onClick={handleToggleFilters}
                                                    size='large'>
                                                    {expandedFilters ? <ClearIcon /> : <TuneIcon />}
                                                </IconButton>
                                            </Grid>
                                        )}
                                        {filterComponents && !expandedFilters && formikProps.values._state && (
                                            <Grid item={true} xs={12}>
                                                <Grid container={true} spacing={1}>
                                                    {(Object.values(formikProps.values._state) as FilterStates[])
                                                        .filter(({value}) => Boolean(value))
                                                        .map(({type, value}) =>
                                                            type === 'array'
                                                                ? value.map(props => props.value && <FilterChip key={props.label} {...props} />)
                                                                : value.value && <FilterChip key={value.label} label={value.label} handleDelete={value.handleDelete} />
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        )}
                                        {filterComponents && !expandedFilters && formikProps.values._state
                                            && !!(Object.values(formikProps.values._state) as FilterStates[])
                                                .flatMap(v => v.type === 'single' ? [v.value] : v.value)
                                                .filter(v => Boolean(v.value)).length
                                            && (
                                                <Grid item={true} xs={12}>
                                                    <Button onClick={() => handleResetFilters(formikProps)} variant='contained' disableElevation={true} fullWidth={true}>
                                                        Limpar filtros
                                                    </Button>
                                                </Grid>
                                            )}
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <Grid container={true} spacing={1}>
                                        {filterComponents?.map((component, index) => (
                                            <Grid item={true} key={index} xs={12} md={6} lg={3}>
                                                <Paper elevation={0} square={false}>
                                                    {component}
                                                </Paper>
                                            </Grid>
                                        ))}
                                        <Grid item={true} xs={12} container={true} spacing={1}>
                                            <Grid item={true} xs={6}>
                                                <Button onClick={() => handleResetFilters(formikProps)} disableElevation={true} variant='contained' fullWidth={true}>
                                                    Limpar
                                                </Button>
                                            </Grid>
                                            <Grid item={true} xs={6}>
                                                <Button color='primary' variant='contained' type='submit' fullWidth={true}>
                                                    Aplicar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Form>
                    )
                }}
            </Formik>
        )
    }, [query, searchInputRef.current, expandedFilters])

    const card = (
        loading ? (
            <div className={classes.loading}>
                <CircularProgress color='primary' />
            </div>
        ) :
            data.map((row, indexRow) =>
                <AsyncCard
                    key={`${row.id}.${indexRow}`}
                    row={row}
                    indexRow={indexRow}
                    contents={contents}
                    headers={headers}
                    getRowLink={getRowLink}
                    moreOptions={moreOptions}
                />
            )
    )

    const pagination = useMemo(() => (
        <Pagination
            count={Math.ceil(count / pageSize)}
            page={page + 1}
            onChange={(_, newPage) => setPage(newPage - 1)}
            color='primary'
        />
    ), [count, page, initialPageSize, pageSize])

    return (
        <Grid container={true}>
            <StyledEngineProvider injectFirst={true}>
                <ThemeProvider theme={theme}>
                    <Grid item={true} xs={12}>
                        {disableToolbar !== true && toolbar}
                    </Grid>
                    <Grid item={true} xs={12}>

                        {card}
                    </Grid>
                    {data.length !== 0 && (
                        <Grid item={true} xs={12} className={classes.paginationSpacer}>
                            {pagination}
                        </Grid>
                    )}
                    {!loading && data.length === 0 && (
                        <div className={classes.empty}>
                            {emptyNode || (
                                <>
                                    <EmptyIcon />
                                    <div>Sem dados disponíveis</div>
                                </>
                            )}
                        </div>
                    )}
                </ThemeProvider>
            </StyledEngineProvider>
        </Grid>
    )
}

const FilterChip: FC<Pick<FilterState, 'handleDelete' | 'label'>> = ({label, handleDelete}) => {
    const {classes} = useStyles()

    return (
        <Grid item={true}>
            <Chip
                size='small'
                label={<Typography variant='caption'>{label}</Typography>}
                classes={{root: classes.chipRoot, label: classes.chipLabel}}
                onDelete={handleDelete}
            />
        </Grid>
    )
}

AsyncMobileCard.defaultProps = {
    loading: false,
    initialPageSize: 20,
} as Partial<AsyncTableProps<any>>
