import {Container, MenuItem, Theme, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

import {TextLink} from 'core/components/text-link';
import {NotificationFragment} from 'generated/graphql';

interface NotificationCardProps {
    notification: NotificationFragment;
    onClick: (notification: NotificationFragment) => void;
}

interface NotificationCardProps {
    notification: NotificationFragment;
    onClick: (notification: NotificationFragment) => void;
}

export const NotificationMenuItem: FC<NotificationCardProps> = ({notification, onClick}) => {
    const {classes} = useStyles();
    const router = useRouter();
    const isCurrentAppLink = notification.application === router.basePath;

    const component = (
        <MenuItem
            onClick={() => onClick(notification)}
            component='a'
            className={notification.isRead ? classes.isRead : classes.notRead}
            divider={true}>
            <Container maxWidth='sm' className={classes.container}>
                <Typography component='p' variant='body1' className={classes.message}>
                    {notification.message}
                </Typography>
            </Container>
        </MenuItem>
    );

    if (isCurrentAppLink) return <TextLink href={notification.path}>{component}</TextLink>;

    return <TextLink href={notification.application + notification.path}>{component}</TextLink>;
};

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        width: '100vw!important'
    },

    message: {
        wordWrap: 'break-word',
        fontSize: '0.875rem',
    },

    isRead: {
        backgroundColor: '#E8E8E8',
        whiteSpace: 'normal',
    },

    notRead: {
        whiteSpace: 'normal',
    }
}));
